import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling
import "@simonwep/pickr/dist/themes/classic.min.css"; // 'classic' theme
import Pickr from "@simonwep/pickr";
import "tippy.js/themes/light.css";
import SVGInject from "svg-inject";

const popOverOptions = `
    <div contenteditable="false" class="popover--options">

    <div class="main--tippy--options">
    <div style="text-align: center;
            font-size: smaller;
            // border-bottom:1px solid #E0E0E0;
            font-weight: 400;">

Border colors</div>
    <div class="color--picker--border"

        style="display:flex;justify-content:space-evenly;margin-top:10px;"
    >
            <input type="button" style="border-bottom:2px solid #c7c7c7;" class="color-picker-bottom color--picker--custom" value="B"/>
            <input type="button" style="border-left:2px solid #c7c7c7;" class="color-picker-left color--picker--custom" value="L"/>
            <input type="button" style="border-top:2px solid #c7c7c7;" class="color-picker-top color--picker--custom" value="T"/>
            <input type="button" style="border-right:2px solid #c7c7c7;" class="color-picker-right color--picker--custom" value="R"/>

        </div>
        <div style="text-align: center; margin-top:20px;margin-bottom:20px;
        font-size: smaller;
        // border-bottom:1px solid #E0E0E0;
        font-weight: 400;">


Background color
</div>


<button style="background-image:linear-gradient(red, yellow);  margin: 0 auto;display: block;" class="color-picker-background color--picker--custom"></button>

<div style="text-align: center; margin-top:20px;margin-bottom:20px;
font-size: smaller;
// border-bottom:1px solid #E0E0E0;
font-weight: 400;">
Layout
</div>
<fieldset id="header--footer--layout" style="display:flex;justify-content:space-evenly">
    <span><input type="radio" value="edge-to-edge" name="layout"><label style="margin-left:10px" for="edge to edge">edge to edge</label></span>
    <span><input type="radio" value="padded" name="layout"><label style="margin-left:10px" for="padded">padded</label></sapn>
</fieldset>


</div>

    </div>



    </div>

    <style>
    .popover--options{
        z-index:99999,
        background:white;
        width:300px;
        padding-bottom:20px;
    }
    .color--picker--custom{
        width:20px;
        height:20px;
        border:none;
        font-size:0.6em;
        border-radius:4px;
    }
    </style>

`;

export default class Editor {
    /* Make Page creation deletion as a function of content size */

    PAGE_IDENTIFIER = ".neo-page";
    options = null;
    activeBlockType = "text";
    buffer = [];
    currentBlockIndex = 0;
    // pageBreakHeight=1122.519685;
    // pageBreakHeight=1553; // A4 - (header + footer)
    pageBreakHeight = 1123;
    editorWidth = 794;
    activeEditorNode = null;
    listPlaceHolders = [];
    activeListPlaceHolderId = "";
    // pageBreakHeight=200;
    container = null;
    prevCursorSelection = null;
    selectedElement = null;
    page = 1;
    isShift = false;
    draggerStart = false;
    tableDragColumn = -1;
    tableDragRow = -1;
    isMouseDown = false;
    selectedRow = null;
    selectedCol = null;
    tableSelection = {
        start: [],
        end: [],
    };
    options = {
        dragger: true,
        ui: true,
    };
    selectableObject = null;
    zIndexes = 0;
    tableBgColoreEditorSelection = null;
    activeEditorSelection = null;
    //append new empty page

    constructor(container, options) {
        /* Configration */
        if (options) Object.assign(this.options, options);

        /* Main Container */
        this.container = document.getElementById(container);
        // document.getElementById("editor").style.width="794px";

        //Reset container width & other default attributes.
        this.container.style.width = `${this.editorWidth}px`;

        //new Editor('editor')

        /* Mouse Events */
        this.addMouseUpListener();
        this.addMouseDownListener();
        this.addMouseOverListener();
        this.addMouseMoveListener();
        this.setupInitialScrollListeners();
        this.handleInput();

        /* Table Dragger Listener */
        this.options.dragger ? this.addDraggerListener() : "";

        /* Key Events */
        this.addKeyUpListener();
        if (this.options.headersFootersAllowEdit) {
            this.addContentClickListener();
        }

        /* Context Menu */
        this.addContextMenuListener();

        /* Handle Paste Event */
        this.handlePaste();
    }

    getAllPages() {
        return [...document.querySelectorAll(".page")];
    }

    attachEditOptions(elem) {
        var that = this;
        tippy(elem, {
            // 'trigger':'click',
            content: popOverOptions,
            placement: "bottom",
            allowHTML: true,
            interactive: true,
            theme: "light",

            onShown(instance) {
                //setup radio option handlers
                var radios = document.querySelectorAll('input[name="layout"]');
                Array.prototype.forEach.call(radios, (radio) =>
                    radio.addEventListener("change", (e) => {
                        that.changeHeaderFooterLayout(instance, e.target.value);
                    })
                );

                const pickr = Pickr.create({
                    el: document.querySelector(".color-picker-bottom"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    appClass: "custom-pickr-class",
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickr.on("change", (color, source, instance) => {
                    that.changeBorderColor("bottom", color.toRGBA().toString(0), elem);
                });

                const pickrl = Pickr.create({
                    el: document.querySelector(".color-picker-left"),
                    useAsButton: true,

                    theme: "classic", // or 'monolith', or 'nano'

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrl.on("change", (color, source, instance) => {
                    that.changeBorderColor("left", color.toRGBA().toString(0), elem);
                });

                const pickrt = Pickr.create({
                    el: document.querySelector(".color-picker-top"),
                    useAsButton: true,

                    theme: "classic", // or 'monolith', or 'nano'

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrt.on("change", (color, source, instance) => {
                    that.changeBorderColor("top", color.toRGBA().toString(0), elem);
                });

                const pickrr = Pickr.create({
                    el: document.querySelector(".color-picker-right"),
                    useAsButton: true,
                    theme: "classic", // or 'monolith', or 'nano'
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrr.on("change", (color, source, instance) => {
                    that.changeBorderColor("right", color.toRGBA().toString(0), elem);
                });

                const pickrbk = Pickr.create({
                    el: document.querySelector(".color-picker-background"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrbk.on("change", (color, source, instance) => {
                    that.changeBackgroundColor("right", color.toRGBA().toString(0), elem);
                });
            },
        });
    }

    reattachIconEvents() {
        [...document.querySelectorAll(".neo--report--icons")].forEach((ico) => {
            this.setupIconClickListener(ico);
        });
    }

    reattachEvents(options) {
        if (options.headersFootersAllowEdit) {
            // var pages = this.getAllPages()
            var pages = document.querySelectorAll(".neo-page");
            pages.forEach((page) => {
                let header = page.querySelector(".editor-header");
                let footer = page.querySelector(".footer");
                this.attachEditOptions(header);
                this.attachEditOptions(footer);
                header.addEventListener("dblclick", this.headerDoubleClickHandler);
                footer.addEventListener("dblclick", this.footerDoubleClickHandler);
            });
            let container = document.querySelector("#editor");
            this.addContentClickListener(container);
        }

        // Reattach event for all editor instances
        var containers = [...document.querySelectorAll("#editor")];

        containers.forEach((container) => {
            //fix: Old template saved without inline style of width
            container.style.width = `${this.editorWidth}px`;
            this.addMouseUpListener(container);
            this.addMouseDownListener(container);
            this.addMouseOverListener(container);
            this.addMouseMoveListener(container);
            this.setupInitialScrollListeners();
            if (options.dragger) this.addDraggerListener(container);
            this.addContextMenuListener(container);
            this.handleInput(container);
            this.handlePaste(container);
            this.addKeyUpListener(container);
        });
        // this.createFooter();
        // this.createHeader();
        [...document.querySelectorAll(".neo--report--icons")].forEach((ico) => {
            this.setupIconClickListener(ico);
        });
    }

    addContentClickListener(container) {
        container = container || this.container;
        container.addEventListener("click", function () {
            let activeHeader = document.getElementsByClassName("activeHeader");
            let activeFooter = document.getElementsByClassName("activeFooter");
            if (activeHeader.length > 0) {
                let htmlTxt = activeHeader[0].innerHTML;
                let style = activeHeader[0]?.style?.cssText;
                document.getElementsByClassName("editor-header").forEach(function (header) {
                    header.offsetHeight <= 60 ? (header.style.minHeight = 60) : header.style.removeProperty("min-height");
                    if (header.className.indexOf("activeHeader") != -1) {
                        var range = window.getSelection().getRangeAt(0);
                        if (range.collapsed) header.classList.remove("activeHeader");
                    } else {
                        header.innerHTML = htmlTxt;
                        header.style.cssText = style;
                    }
                    // header.contentEditable = false;
                });
            } else if (activeFooter.length > 0) {
                let htmlTxt = activeFooter[0].innerHTML;
                let style = activeFooter[0]?.style?.cssText;
                document.getElementsByClassName("footer").forEach(function (footer) {
                    if (footer.className.indexOf("activeFooter") != -1) {
                        var range = window.getSelection().getRangeAt(0);
                        if (range.collapsed) footer.classList.remove("activeFooter");
                    } else {
                        footer.innerHTML = htmlTxt;
                        footer.style.cssText = style;
                    }
                    // footer.contentEditable = false;
                });
            }
        });
    }

    addDraggerListener() {
        let that = this;
        document.getElementById("dragger_elem").addEventListener("mousedown", function (event) {
            if (event.detail > 1) {
                event.preventDefault();
            }
            that.draggerStart = true;
            event.preventDefault();
        });
        document.getElementById("dragger_elem").addEventListener("mouseup", function (event) {
            that.draggerStart = false;
            event.preventDefault();
        });
    }

    addMouseUpListener(editorContainer) {
        let that = this;
        let container = editorContainer || this.container;
        container.addEventListener("mouseup", function () {
            // When mouse is pressed & released inside neo-page (editor), record , which node the mouse was pressed.

            try {
                var node = that.getActiveNode();
                if (node.closest(".neo-page")) {
                    that.activeEditorNode = node;
                    that.activeEditorSelection = that.saveSelection();
                }
            } catch (err) {}

            that.isMouseDown = false;
            if (that.tableSelection.end.length == 0) {
                that.tableSelection.start = [];
            }
            if (event.target.nodeName != "path" && event.target.parentNode && event.target.parentNode.className != "resizable") {
                if (that.selectedElement) {
                    that.selectedElement.style.border = "none";
                    that.selectedElement = null;
                }

                setTimeout(function () {
                    let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
                    that.prevCursorSelection = selection.getRangeAt && selection.type != "None" ? selection.getRangeAt(0).cloneRange() : null;
                });
            }
        });
    }

    setupInitialScrollListeners() {
        let dragger = document.getElementById("dragger_elem");
        document.addEventListener("scroll", (e) => {
            // dragger.style.visibility = "hidden";
        });
    }

    changeTableWidth(e) {
        var activeTable = document.querySelector("[table-selected='true']");
        activeTable.style.width = `${e.target.value}px`;
    }

    addMouseDownListener(editorContainer) {
        let that = this;
        let container = editorContainer || this.container;
        container.addEventListener("mousedown", function (event) {
            // remove highlight from selectable objects

            that.selectableObject && that.selectableObject?.style?.removeProperty("border");
            that.selectableObject = null;
            if (event.detail > 1) {
                event.preventDefault();
                // of course, you still do not know what you prevent here...
                // You could also check event.ctrlKey/event.shiftKey/event.altKey
                // to not prevent something useful.
            }

            if (event.which == 3) {
                return false;
            }

            // if(event.target.nodeName != "SELECT"){
            //     var activeListPlaceholder = document.querySelector(`select[data-select-id="${that.activeListPlaceHolderId}"]`)
            //     activeListPlaceholder.style.display = 'none';
            // }

            that.isMouseDown = true;
            that.closeContextMenu();

            if (that.options.dragger) {
                that.draggerStart ? (that.draggerStart = false) : "";

                document.getElementById("dragger_elem")?.style?.setProperty("display", "none");
                let delem = null;
                if (event.target.getAttribute("dragelem") === "true") {
                    delem = event.target;
                } else if (event.target.parentNode.getAttribute("dragelem") === "true") {
                    delem = event.target.parentNode;
                } else if (event.target.offsetParent && event.target.offsetParent.getAttribute("dragelem") === "true") {
                    delem = event.target.offsetParent;
                }

                if (delem) {
                    setTimeout(function () {
                        that.makeElementDragable(delem);
                    });
                }
            }

            if (event.target.nodeName == "TD") {
                //set it as active table
                var activeTable = event.target.closest("table");
                document.querySelectorAll("table").forEach((tab) => {
                    tab.setAttribute("table-selected", false);
                });
                activeTable.setAttribute("table-selected", true);
                that.tableSelection.start = [event.target.parentNode.rowIndex, event.target.cellIndex];
                that.tableSelection.end = [];

                //Table background fix
                that.tableBgColor = event.target?.style?.background ? event.target?.style?.background : null;

                //display table option slider
                try {
                    var tableSlider = document.getElementById("table--width--slider");
                    tableSlider.style.display = "flex";
                } catch (e) {}
            }

            if (event.target.parentNode.className == "resizable") {
                let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
                selection.removeAllRanges();
                that.prevCursorSelection = null;
                that.selectedElement = event.target.parentNode;
                that.selectedElement.style.border = "2px solid #23a0ea";
            }
            // else if(event.target.nodeName=="path"){
            //     let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
            //     selection.removeAllRanges();
            //     that.prevCursorSelection = null;
            //     that.selectedElement = event.target.ownerSVGElement;
            //     that.selectedElement.style.border = "2px solid #23a0ea";
            // }
            else if (event.target.className != "resizable") {
                if (that.selectedElement) {
                    that.selectedElement.style.border = "none";
                    that.selectedElement = null;
                }
                let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
                that.prevCursorSelection = selection.getRangeAt && selection.type != "None" ? selection.getRangeAt(0).cloneRange() : null;
            }
            if (event.target.nodeName !== "TD") {
                try {
                    var tableSlider = document.getElementById("table--width--slider");
                    tableSlider.style.display = "none";
                    const contentSection = document.getElementsByClassName("content-section")[0];
                    if (contentSection) contentSection.style.marginTop = "110px";
                } catch (e) {}
            }
        });
    }

    addMouseOverListener(editorContainer) {
        let that = this;
        let container = editorContainer || this.container;
        container.addEventListener("mouseover", function (event) {
            if (!that.draggerStart && ((event.target.offsetParent && event.target.offsetParent.nodeName == "TABLE") || event.target.className == "y_resize" || event.target.className == "x_resize")) {
                let isDragItem = document.getElementsByClassName("y_resize");
                if (isDragItem.length == 0) {
                    let table = event.target.offsetParent;
                    let isSelected = table.getAttribute("table-selected");
                    if (isSelected === "false" || isSelected == null) {
                        let prevSelectedTable = document.querySelector("[table-selected='true']");
                        prevSelectedTable ? prevSelectedTable.setAttribute("table-selected", false) : "";
                        let activeTable = event.path.find((val) => {
                            return val.tagName == "TABLE";
                        });
                        activeTable ? activeTable.setAttribute("table-selected", true) : "";
                    }
                    if (that.options.dragger) that.addTableDraggers(table, container);
                }
            } else {
                if (that.tableDragRow == -1 && that.tableDragColumn == -1 && that.options.dragger) {
                    that.removeTableDraggers();
                }
            }
        });
    }

    addMouseMoveListener(editorContainer) {
        let that = this;
        let container = editorContainer || this.container;
        container.addEventListener("mousemove", function (event) {
            if (that.tableDragColumn != -1 && !that.draggerStart) {
                let isDragItem = document.getElementsByClassName("y_resize");
                let elem = isDragItem[that.tableDragColumn - 1];
                let leftPos = event.clientX;
                elem.style.left = leftPos + "px";
            }
            if (that.tableDragRow != -1 && !that.draggerStart) {
                let isDragItem = document.getElementsByClassName("x_resize");
                let elem = isDragItem[that.tableDragRow];
                let topPos = event.clientY;
                elem.style.top = topPos + "px";
            }
            if (that.isMouseDown && that.tableDragRow == -1 && that.tableDragColumn == -1) {
                if (event.target.tagName == "TD" || event.target.tagName == "TH") {
                    that.tableSelectionCompute(event);
                }
            } else if (that.draggerStart) {
                let range = that.prevCursorSelection;
                let dragger = document.getElementById("dragger_elem");
                //To fix dragger functionality in single template as there is no main-wrapper so adding editor as a wrapper
                let wrapper = document.getElementById("main-wrapper") || document.getElementById("editor");
                let element = that.selectedElement;

                let elem = element ? element : range.commonAncestorContainer.parentNode.offsetParent;

                if (elem.nodeName == "TR" || elem.nodeName == "TABLE") {
                    elem = range.commonAncestorContainer.parentElement.offsetParent;
                }

                let scrollAmount = document.scrollingElement.scrollTop;

                // dragger.style.left = event.clientX-10+"px";
                // dragger.style.top = event.clientY+scrollAmount-10+"px";

                dragger.style.left = `${event.clientX - 7}px`;
                dragger.style.top = `${event.clientY - 9}px`;

                // let left = event.clientX-wrapper.offsetLeft;
                // let top = event.clientY+scrollAmount - wrapper.offsetHeight;

                // document.getElementById("debug--area").innerText = `
                //     clientX:${event.clientX} , clientY:${event.clientY}
                //     screenX:${event.screenX} , screenY:${event.screenY}
                //     scroll: ${scrollAmount}
                //     top: ${top}
                //     left: ${left}
                // `;
                // // let top = event.screenY;
                // document.getElementById("debug--area").style.position = "fixed"
                // document.getElementById("debug--area").style.left="250px"
                // elem.style.left = left-190 + "px";
                // elem.style.top = top + "px";
                // var dgRect = dragger.getBoundingClientRect()
                // elem.style.left = `${dgRect.left}px`;
                // elem.style.top = `${dgRect.top}px`;

                let top = Math.abs(elem.closest(".neo-page").getBoundingClientRect().top - event.clientY);
                let left = event.clientX - wrapper.offsetLeft;
                // let top = event.clientY;
                var page = elem.closest(".neo-page");

                elem.style.top = top + "px";
                elem.style.left = left - 190 + "px";

                // Check bounds & make sure overlay elements do not overflow outside page

                //Check right bounds
                if (parseInt(elem?.style?.left, 10) + parseInt(elem?.style?.width, 10) >= 794) {
                    elem.style.left = 794 - parseInt(elem?.style?.width, 10) - 10 + "px";
                }

                //Check left bounds
                if (parseInt(elem?.style?.left, 10) <= 0) {
                    elem.style.left = "-8px";
                }

                //Check bottom bounds
                if (parseInt(elem?.style?.top, 10) + elem?.clientHeight >= 1123) {
                    elem.style.top = 1123 - elem?.clientHeight - 10 + "px";
                }
            }
        });
    }

    tableSelectionCompute(event) {
        let that = this;
        let table = document.querySelector("[table-selected='true']");

        /*
        how slection is maintained
        tableSelection = {
            start[0,0],
            end:[3,2]
        }
        */

        //current cell on which mouse is over must be the last in selection.
        that.tableSelection.end = [event.target.parentNode.rowIndex, event.target.cellIndex];

        if (that.tableSelection.start[0] == event.target.parentNode.rowIndex && that.tableSelection.start[1] == event.target.cellIndex) {
            table.rows[that.tableSelection.start[0]].cells.forEach(function (cell) {
                cell.classList.remove("table-selection");
            });
            return;
        }
        table.rows.forEach(function (row, index) {
            if ((index >= that.tableSelection.start[0] && index <= that.tableSelection.end[0]) || (index <= that.tableSelection.start[0] && index >= that.tableSelection.end[0])) {
                row.cells.forEach(function (cell, index) {
                    if ((index >= that.tableSelection.start[1] && index <= that.tableSelection.end[1]) || (index <= that.tableSelection.start[1] && index >= that.tableSelection.end[1])) {
                        cell.classList.add("table-selection");
                    } else {
                        cell.classList.remove("table-selection");
                    }
                });
            } else {
                row.cells.forEach(function (cell) {
                    cell.classList.remove("table-selection");
                });
            }
        });
    }

    addTableDraggers(table, editorContainer) {
        let container = editorContainer || this.container;
        let cols = table.rows[0].cells.length;
        let elm_bound = table.getBoundingClientRect();
        let cols_height = elm_bound.height;
        for (let i = 0; i < table.rows.length; i++) {
            let yDiv = document.createElement("div");
            let row = table.rows[i];
            if (!row.childNodes.rowSpan) {
                yDiv.className = "x_resize";
                yDiv.setAttribute("data-resizerow", i);
                let leftPos = elm_bound.left;
                let topPos = row.offsetTop + row.clientHeight + elm_bound.top;
                yDiv.style.cssText = "left: " + leftPos + "px; height:5px; width:" + row.clientWidth + "px; top:" + topPos + "px;";
                container.append(yDiv);
                yDiv.addEventListener("mousedown", this.tableRowMouseDown.bind(this));
                yDiv.addEventListener("mouseup", this.tableRowMouseUp.bind(this));
            }
        }
        for (let i = 1; i < cols; i++) {
            let column = table.rows[0].cells[i - 1];
            let yDiv = document.createElement("div");
            yDiv.className = "y_resize";
            yDiv.setAttribute("data-resizecol", i);
            let leftPos = column.offsetLeft + column.offsetWidth + elm_bound.left;
            let topPos = elm_bound.top;
            yDiv.style.cssText = "left: " + leftPos + "px; height:" + cols_height + "px;top:" + topPos + "px;";
            container.append(yDiv);
            yDiv.addEventListener("mousedown", this.tableColumnMouseDown.bind(this));
            yDiv.addEventListener("mouseup", this.tableColumnMouseUp.bind(this));
        }
    }

    tableRowMouseDown(e) {
        this.tableDragRow = e.target.getAttribute("data-resizerow");
        e.target.style.background = "blue";
    }

    tableRowMouseUp(e) {
        e.target.style.background = "transparent";
        if (this.tableDragRow != -1) {
            let selectedTable = document.querySelector("[table-selected='true']");
            let tableBonds = selectedTable.getBoundingClientRect();
            let row = selectedTable.rows[this.tableDragRow];
            // let rowTopOffset = row.offsetTop;
            row.style.height = event.clientY - (tableBonds.top + row.offsetTop) + "px";
            this.tableDragRow = -1;
            this.removeTableDraggers();
        }
    }

    tableColumnMouseDown(e) {
        this.tableDragColumn = e.target.getAttribute("data-resizecol");
        e.target.style.background = "blue";
    }

    tableColumnMouseUp(e) {
        let that = this;
        e.target.style.background = "transparent";
        if (that.tableDragColumn != -1) {
            let selectedTable = document.querySelector("[table-selected='true']");
            let tableBonds = selectedTable.getBoundingClientRect();
            let columnOffset = selectedTable.rows[0].cells[that.tableDragColumn - 1].offsetLeft;
            let oldColumnWidth = parseFloat(selectedTable.rows[0].cells[that.tableDragColumn - 1].style.width);
            let nextColumnWidth = that.tableDragColumn < selectedTable.rows[0].cells.length ? parseFloat(selectedTable.rows[0].cells[that.tableDragColumn].style.width) : 0;
            let newColumnWidth = ((event.clientX - tableBonds.left - columnOffset) * 100) / tableBonds.width;
            let diffColumn = oldColumnWidth - newColumnWidth;
            selectedTable.rows.forEach(function (row) {
                if (that.tableDragColumn < selectedTable.rows[0].cells.length) {
                    row.cells[that.tableDragColumn - 1].style.width = newColumnWidth + "%";
                    row.cells[that.tableDragColumn].style.width = nextColumnWidth + diffColumn + "%";
                }
            });
            that.tableDragColumn = -1;
            that.removeTableDraggers();
        }
    }

    removeTableDraggers() {
        let isDragItemY = document.getElementsByClassName("y_resize");
        let isDragItemX = document.getElementsByClassName("x_resize");
        while (isDragItemY.length > 0) {
            isDragItemY[0].parentNode.removeChild(isDragItemY[0]);
        }
        while (isDragItemX.length > 0) {
            isDragItemX[0].parentNode.removeChild(isDragItemX[0]);
        }
    }

    addKeyUpListener(container) {
        let that = this;
        container = container || this.container;
        container.addEventListener("keyup", function () {
            that.isShift = false;
            setTimeout(function () {
                let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
                that.prevCursorSelection = selection.getRangeAt && selection.type != "None" ? selection.getRangeAt(0).cloneRange() : null;
            });
        });
    }

    makeElementDragable(elem) {
        let that = this;
        let range = this.prevCursorSelection;
        if (range && !elem) {
            that.selectedElement = null;
            if (range.commonAncestorContainer.nodeName == "#text") {
                if (range.commonAncestorContainer.parentNode.nodeName == "TD") {
                    that.selectedElement = range.commonAncestorContainer.parentNode.closest("table");
                } else {
                    that.selectedElement = range.commonAncestorContainer.parentNode;
                }
            } else if (range.commonAncestorContainer.nodeName == "TD") {
                that.selectedElement = range.commonAncestorContainer.offsetParent;
            } else {
                that.selectedElement = range.commonAncestorContainer.parentNode;
            }
        } else {
            that.selectedElement = elem ? elem : that.selectedElement;
        }
        if (that.selectedElement.querySelector(".neo-page") && that.selectedElement.querySelector(".content--area")) {
            return;
        }
        that.selectedElement.nodeName == "TABLE" ? that.selectedElement.style.setProperty("width", that.selectedElement.offsetWidth + "px") : "";
        that.selectedElement.style.setProperty("position", "absolute");
        that.selectedElement.style.setProperty("transition", "none");
        that.selectedElement.style.setProperty("margin", "0 8px");
        that.selectedElement.style.setProperty("z-index", this.zIndexes);
        that.selectedElement.setAttribute("dragElem", true);
        let wrapper = document.getElementById("main-wrapper");
        let dragger = document.getElementById("dragger_elem");

        dragger.style.display = "block";
        dragger.style.zIndex = "1000";

        var rect = that.selectedElement.getBoundingClientRect();
        dragger.style.left = `${rect.left}px`;
        dragger.style.top = `${rect.top}px`;

        // dragger.style.left = that.selectedElement.offsetLeft+wrapper.offsetLeft+"px";
        // dragger.style.top = that.selectedElement.offsetTop + wrapper.offsetTop +"px";
    }

    overlayElem() {
        let range = this.getRange();
        this.zIndexes = this.zIndexes + 1;
        if (!range) {
            let element = this.selectedElement;
            element.style.setProperty("position", "absolute");
            element.style.setProperty("z-index", this.zIndexes);
            this.makeElementDragable();
            return;
        } else {
            this.makeElementDragable();
        }
    }

    closeContextMenu() {
        // ContextMenus will not appear in screen other tan section creator,
        // so better to ignore it?
        try {
            document.getElementById("contextMenuContainer").style.display = "none";
            document.getElementById("colorContextMenu").style.display = "none";
        } catch (e) {}
    }

    addShape(icon) {
        let range = this.prevCursorSelection;
        let imgWrap = document.createElement("div");
        let img = document.createElement("img");
        img.src = icon;
        img.setAttribute("draggable", "false");
        imgWrap.style.height = "auto";
        imgWrap.style.width = "200px";
        imgWrap.style.marginRight = "15px";
        imgWrap.classList.add("resizable");
        imgWrap.setAttribute("contenteditable", false);
        imgWrap.appendChild(img);
        range.insertNode(imgWrap);
    }

    addContextMenuListener(editorContainer) {
        let container = editorContainer || this.container;
        let that = this;
        container.addEventListener("contextmenu", function (e) {
            let elem = e.target.className.indexOf("activeHeader") != -1 || e.target.className.indexOf("activeFooter") != -1 ? e.target : e.target.parentNode.className.indexOf("activeHeader") != -1 || e.target.parentNode.className.indexOf("activeFooter") != -1 ? e.target.parentNode : null;
            if (elem) {
                e.preventDefault();
                let menu = document.getElementById("colorContextMenu");
                menu.style.left = e.clientX + "px";
                menu.style.top = e.clientY + "px";
                menu.style.display = "block";
            }

            if (e.target.parentNode.nodeName == "TR") {
                e.preventDefault();
                that.selectedRow = e.target.parentNode.rowIndex;
                that.selectedCol = e.target.cellIndex;
                let menu = document.getElementById("contextMenuContainer");
                menu.style.left = e.clientX + "px";
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
                    menu.style.top = e.clientY - 100 + "px";
                } else {
                    menu.style.top = e.clientY + "px";
                }
                menu.style.display = "block";
            }
        });
    }

    clickContextItem(e, clicked) {
        e.stopPropagation();
        if (clicked == "delete_row") {
            let activeTable = document.querySelector("[table-selected='true']");
            let index = parseInt(this.selectedRow);
            activeTable.deleteRow(index);
        } else if (clicked == "delete_table") {
            let activeTable = document.querySelector("[table-selected='true']");
            activeTable.parentElement.removeChild(activeTable);
        } else if (clicked == "merge") {
            // alert(1)
            let activeTable = document.querySelector("[table-selected='true']");
            // FInd out how to extend(using colspan & rowspan ) in both directions
            let colDiff = Math.abs(this.tableSelection.end[1] - this.tableSelection.start[1]);
            let rowDiff = Math.abs(this.tableSelection.end[0] - this.tableSelection.start[0]);
            // this.addTableDraggers(activeTable)

            if (this.tableSelection.end[0] < this.tableSelection.start[0]) {
                var tmp = this.tableSelection.start;
                this.tableSelection.start = this.tableSelection.end;
                this.tableSelection.end = tmp;
            }

            if (rowDiff > 0) {
                activeTable.rows[this.tableSelection.start[0]].cells[this.tableSelection.start[1]].rowSpan = rowDiff + 1;
            }
            activeTable.rows[this.tableSelection.start[0]].cells[this.tableSelection.start[1]].colSpan = colDiff + 1;

            activeTable.rows[this.tableSelection.start[0]].cells[this.tableSelection.start[1]].classList.remove("table-selection");

            for (let i = 0; i < rowDiff + 1; i++) {
                let cols = i == 0 ? colDiff : colDiff + 1;
                let cellIndex = i == 0 ? this.tableSelection.start[1] + 1 : this.tableSelection.start[1];
                for (let j = 0; j < cols; j++) {
                    try {
                        activeTable.rows[this.tableSelection.start[0] + i].deleteCell(cellIndex);
                    } catch (err) {}
                }
            }
            this.tableSelection = {start: [], end: []};
        }
        this.closeContextMenu();
    }

    clickContextItemChild(e) {
        e.stopPropagation();
        let clicked = e.target.getAttribute("value");
        let activeTable = document.querySelector("[table-selected='true']");
        if (clicked == "insert_row_above" || clicked == "insert_row_below") {
            this.addTableRow(clicked);
        }
        if (clicked == "insert_col_above" || clicked == "insert_col_below") {
            let index = clicked == "insert_col_above" ? this.selectedCol : JSON.parse(this.selectedCol) + 1;
            for (let i = 0; i < activeTable.rows.length; i++) {
                activeTable.rows[i].insertCell(index);
            }
        }
        this.closeContextMenu();
    }

    addSocialIcon(selectedSocialIcon) {
        let range = this.prevCursorSelection;
        let imgWrap = document.createElement("div");
        let img = document.createElement("img");
        img.src = selectedSocialIcon;
        img.setAttribute("draggable", "false");
        imgWrap.style.height = "auto";
        imgWrap.style.width = "60px";
        imgWrap.classList.add("resizable");
        imgWrap.setAttribute("contenteditable", false);
        imgWrap.appendChild(img);
        range.insertNode(imgWrap);
    }

    getPageHeight(pageId) {
        var nodesInPage = this.getContentsOfPage(pageId);
        if (nodesInPage) nodesInPage = [...nodesInPage];
        let headerAndFooter = this.getHeaderAndFooterHeight(pageId);

        const SumReducer = (acc, current) => {
            return acc + current.offsetHeight;
        };
        const height = nodesInPage.reduce(SumReducer, 0) + headerAndFooter.header + headerAndFooter.footer;
        return height;
    }

    isContentOverflowingOnNodeAddition(pageId, nodeHeight) {
        return nodeHeight + this.getPageHeight(pageId) > this.pageBreakHeight;
    }

    getLinkedTable(currentPageId, tableId, prev = false) {
        var regex = /\d+/g;
        var id = parseInt(currentPageId.match(regex)[0]);
        var pageId = null;
        var totalPages = this.getPageCount();

        if (prev) {
            //                 pageId = `page--${id-1}`
            pageId = currentPageId;
            var pageContents = [...this.getContentsOfPage(pageId)];
            var lastNode = pageContents[pageContents.length - 1];
            if (lastNode && lastNode.childNodes.length <= 2 && lastNode.firstChild.nodeName === "TABLE" && lastNode.firstChild.getAttribute("data-table-id") === tableId) {
                return lastNode.firstChild;
            } else {
                var tab = this.createLinkedTable(tableId);
                //remove first default row of this table
                tab.rows[0].parentNode.removeChild(tab.rows[0]);
                return tab;
            }
        } else {
            pageId = `page--${id + 1}`;
            var pageContents = [...this.getContentsOfPage(pageId)];
            if (totalPages > id) {
                var firstNode = pageContents[0];
                if (firstNode && firstNode.childNodes.length <= 2 && firstNode.firstChild.nodeName === "TABLE" && firstNode.firstChild.getAttribute("data-table-id") === tableId) return firstNode.firstChild;
            }

            //else create a new linked table
            var tab = this.createLinkedTable(tableId);
            //remove first default row of this table
            tab.rows[0].parentNode.removeChild(tab.rows[0]);
            return tab;
        }
    }

    createLinkedTable(tableId) {
        // A linked table is created when there is no space in the page & table row is being added
        // or content is being overflown.

        var pageId = this.getActivePageId();
        var page = this.getPageById(pageId);
        let activeTable = document.querySelector(`[data-table-id='${tableId}']`);
        let cols = activeTable.rows[0].cells.length;
        let table = this.createTable(1, cols);
        table.setAttribute("data-table-id", tableId);
        var emptyRow = this.createNewRow();
        emptyRow.classList.add(pageId);
        emptyRow.classList.add("table--container");
        emptyRow.appendChild(table);

        this.addToPage(page, emptyRow);
        // Do the reshifting of newly created table, since we know it will already overflow
        //     this.shiftContentToNextPages(pageId)

        //     //Do the merging of tables, if required here,
        //     var nextPageId = this.getNextPageId()
        //     var nextPage = this.getPageById(nextPageId)
        //     var linkedTableRows = [...nextPage.querySelectorAll(`[data-table-id="${tableId}"`)]
        //    if(linkedTableRows.length>1){

        //        ;[...linkedTableRows[1].querySelectorAll('tr')].forEach(tr=>{
        //            linkedTableRows[0].querySelector('tbody').appendChild(tr)
        //        })
        //        linkedTableRows[1].closest('div').parentNode.removeChild(linkedTableRows[1].closest('div'))

        //    }

        //    //remeber to copy attributes to which this linked table is created.
        //    table.rows.forEach(r => {
        //        r.childNodes.forEach(cell => {
        //            cell.style.border = activeTable.style.border
        //        })
        //    })

        return table;
    }

    cloneAttributes(target, source) {
        [...source.attributes].forEach((attr) => {
            target.setAttribute(attr.nodeName === "id" ? "data-id" : attr.nodeName, attr.nodeValue);
        });
    }

    getLinkedRow(currentPageId, rowId, cells, prev = false) {
        var regex = /\d+/g;
        var id = parseInt(currentPageId.match(regex)[0]);
        var pageId = null;
        var totalPages = this.getPageCount();

        if (prev) {
            pageId = currentPageId;

            var pageContents = [...this.getContentsOfPage(pageId)];
            var lastNode = pageContents.pop();
            if (lastNode && lastNode.childNodes.length <= 2 && lastNode.firstChild.nodeName === "TABLE" && lastNode.firstChild.rows[lastNode.firstChild.rows.length - 1].getAttribute("data-row-id") === rowId) {
                return lastNode.firstChild.rows[lastNode.firstChild.rows.length - 1];
            } else {
                var row = document.createElement("tr");
                row.setAttribute("data-row-id", rowId);
                let cellIndex = 0;
                for (let c of cells) {
                    var _cell = row.insertCell();
                    _cell.style.width = c.style.width;
                    _cell.style.backgroundColor = c.style.backgroundColor;
                    _cell.style.border = c.style.border;
                    _cell.style.padding = c.style.padding;
                    cellIndex = cellIndex + 1;
                }
                return row;
            }
        } else {
            pageId = `page--${id + 1}`;
            if (totalPages > id) {
                var pageContents = [...this.getContentsOfPage(pageId)];
                var firstNode = pageContents[0];
                if (firstNode && firstNode.childNodes.length <= 2 && firstNode.firstChild.nodeName === "TABLE" && firstNode.firstChild.rows[0].getAttribute("data-row-id") === rowId) return firstNode.firstChild.rows[0];
            }
            var row = document.createElement("tr");
            row.setAttribute("data-row-id", rowId);
            let cellIndex = 0;
            for (let c of cells) {
                var _cell = row.insertCell();
                _cell.style.width = c.style.width;
                _cell.style.backgroundColor = c.style.backgroundColor;
                _cell.style.border = c.style.border;
                _cell.style.padding = c.style.padding;
                cellIndex = cellIndex + 1;
            }
            return row;
        }
    }

    /**
     * Inserts a new table row before/after the active row,
     * preserves the attributes of current active row.
     * @param {type} type  Row type, above or below
     */
    addTableRow(type) {
        var pageId = this.getActivePageId();

        let activeTable = document.querySelector("[table-selected='true']");
        var activeTableId = activeTable.getAttribute("data-table-id");

        // Get active row
        var activeNode = this.activeEditorNode;
        var activeRow = activeNode.closest("tr");

        let totalCells = activeRow.cells.length;

        //Stroe active table border color attributes.
        var borderAttrs = activeTable.style.border;

        let index = type == "insert_row_above" ? this.selectedRow : JSON.parse(this.selectedRow) + 1;

        var tableRowHeight = activeTable.rows[0].cells[0].offsetHeight;
        var tableRowWidth = activeTable.rows[0].cells[0].offsetWidth;

        if (this.isContentOverflowingOnNodeAddition(pageId, tableRowHeight)) {
            this.createLinkedTable(activeTableId);
        } else {
            let row = activeTable.insertRow(index);
            row.style.width = tableRowWidth;
            for (let j = 0; j < totalCells; j++) {
                var cell = row.insertCell(j);
                var cellColSpan = activeRow.cells[j].getAttribute("colspan");
                var cellColors = activeRow.cells[j].style.backgroundColor;

                //restore borer attrs
                cell.setAttribute("colspan", cellColSpan);
                cell.style.backgroundColor = cellColors;

                cell.style.border = borderAttrs;
                cell.style.padding = "4px";
            }
        }
        this.shiftContentToNextPages(pageId);
    }

    getLayoutMetrix() {
        let element = null;
        if (this.prevCursorSelection) {
            let node = this.prevCursorSelection.commonAncestorContainer.nodeName;
            if (node == "#text") {
                element = this.prevCursorSelection.commonAncestorContainer.parentNode;
            } else if (node == "TH" || node == "TD") {
                element = this.prevCursorSelection.commonAncestorContainer.offsetParent.parentNode;
            } else {
                element = this.prevCursorSelection.commonAncestorContainer;
            }
        } else {
            element = this.selectedElement;
        }

        let obj = {
            margins: {},
            paddings: {},
        };

        obj["margins"]["left"] = parseInt(element.style.marginLeft);
        obj["margins"]["right"] = parseInt(element.style.marginRight);
        obj["margins"]["top"] = parseInt(element.style.marginTop);
        obj["margins"]["bottom"] = parseInt(element.style.marginBottom);
        obj["paddings"]["left"] = parseInt(element.style.paddingLeft);
        obj["paddings"]["right"] = parseInt(element.style.paddingRight);
        obj["paddings"]["top"] = parseInt(element.style.paddingTop);
        obj["paddings"]["bottom"] = parseInt(element.style.paddingBottom);
        return obj;
    }

    setLayoutMetrix(metrix) {
        let element = null;
        if (this.prevCursorSelection) {
            let node = this.prevCursorSelection.commonAncestorContainer.nodeName;
            if (node == "#text") {
                element = this.prevCursorSelection.commonAncestorContainer.parentNode;
            } else if (node == "TH" || node == "TD") {
                element = this.prevCursorSelection.commonAncestorContainer.offsetParent.parentNode;
            } else {
                element = this.prevCursorSelection.commonAncestorContainer;
            }
        } else {
            element = this.selectedElement;
        }
        element.style.marginLeft = metrix.margins.left + "px";
        element.style.marginRight = metrix.margins.right + "px";
        element.style.marginTop = metrix.margins.top + "px";
        element.style.marginBottom = metrix.margins.bottom + "px";
        element.style.paddingLeft = metrix.paddings.left + "px";
        element.style.paddingRight = metrix.paddings.right + "px";
        element.style.paddingTop = metrix.paddings.top + "px";
        element.style.paddingBottom = metrix.paddings.bottom + "px";
    }

    resetElemStyle() {
        let range = window.getSelection().getRangeAt(0);
        if (range.collapsed) return;
        document.execCommand("RemoveFormat", false, null);
    }

    changeBorderColor(side, color, elem) {
        // elem.style.setProperty(`border-${side}`, `2px solid ${color}`)

        // update all border colors
        if ([...elem.classList].includes("editor-header")) {
            //change all headers
            [...document.querySelectorAll(".editor-header")].forEach((header) => header.style.setProperty(`border-${side}`, `2px solid ${color}`));
        }
        // update all border colors
        if ([...elem.classList].includes("footer")) {
            //change all headers
            [...document.querySelectorAll(".footer")].forEach((header) => header.style.setProperty(`border-${side}`, `2px solid ${color}`));
        }
    }

    changeBackgroundColor(side, color, elem) {
        if ([...elem.classList].includes("editor-header")) {
            //change all headers
            [...document.querySelectorAll(".editor-header")].forEach((header) => header.style.setProperty(`background`, `${color}`));
        }
        if ([...elem.classList].includes("footer")) {
            //change all headers
            [...document.querySelectorAll(".footer")].forEach((header) => header.style.setProperty(`background`, `${color}`));
        }
    }
    changeHeaderFooterLayout(e, layout) {
        if (layout === "padded") {
            e.reference.style.removeProperty("margin-left");
            e.reference.style.removeProperty("margin-right");
        }
        if (layout === "edge-to-edge") {
            e.reference.style.margin = "0px -96px";
        }
    }
    createHeader() {
        //header

        var header = document.createElement("div");
        // header.style.padding = "0px 96px";

        header.style.marginLeft = "-96px";
        header.style.marginRight = "-96px";

        header.classList.add("editor-header");
        // Make this non selectable, so that when ctrl - A is selected, content can be deleted
        // header.style.setProperty('-moz-user-select','none')
        // header.style.setProperty('-khtml-user-select','none')
        // header.style.setProperty('-webkit-user-select','none')
        // header.style.setProperty('-o-user-select','none')
        // header.style.setProperty('user-select','none')
        // For enabling the user select on header.
        var that = this;

        tippy(header, {
            // 'trigger':'click',
            content: popOverOptions,
            placement: "bottom",
            allowHTML: true,
            interactive: true,
            theme: "light",

            onShown(instance) {
                //setup radio option handlers
                var radios = document.querySelectorAll('input[name="layout"]');
                Array.prototype.forEach.call(radios, (radio) =>
                    radio.addEventListener("change", (e) => {
                        that.changeHeaderFooterLayout(instance, e.target.value);
                    })
                );

                const pickr = Pickr.create({
                    el: document.querySelector(".color-picker-bottom"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    appClass: "custom-pickr-class",
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickr.on("change", (color, source, instance) => {
                    that.changeBorderColor("bottom", color.toRGBA().toString(0), header);
                });

                const pickrl = Pickr.create({
                    el: document.querySelector(".color-picker-left"),
                    useAsButton: true,

                    theme: "classic", // or 'monolith', or 'nano'

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrl.on("change", (color, source, instance) => {
                    that.changeBorderColor("left", color.toRGBA().toString(0), header);
                });

                const pickrt = Pickr.create({
                    el: document.querySelector(".color-picker-top"),
                    useAsButton: true,

                    theme: "classic", // or 'monolith', or 'nano'

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrt.on("change", (color, source, instance) => {
                    that.changeBorderColor("top", color.toRGBA().toString(0), header);
                });

                const pickrr = Pickr.create({
                    el: document.querySelector(".color-picker-right"),
                    useAsButton: true,
                    theme: "classic", // or 'monolith', or 'nano'
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrr.on("change", (color, source, instance) => {
                    that.changeBorderColor("right", color.toRGBA().toString(0), header);
                });

                const pickrbk = Pickr.create({
                    el: document.querySelector(".color-picker-background"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,

                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrbk.on("change", (color, source, instance) => {
                    that.changeBackgroundColor("right", color.toRGBA().toString(0), header);
                });
            },
        });
        //create an active div block
        if (document.getElementsByClassName("editor-header").length > 0) {
            header.style.cssText = document.getElementsByClassName("editor-header")[0].style.cssText;
            header.innerHTML = document.getElementsByClassName("editor-header")[0].innerHTML;

            //Reset any class that have been present in the first header, ex, page-1 class.
            [...header.childNodes].map((item) => {
                item.removeAttribute("class");
                //now reassign proper class
                item.classList.add("header--item");
            });
        } else {
            var div = document.createElement("div");
            div.classList.add("header--item");
            div.style.minHeight = "20px";
            // div.style.margin = '0px 96px'
            header.style.minHeight = "60px";
            header.appendChild(div);
        }

        header.contentEditable = false;

        header.addEventListener("dblclick", this.headerDoubleClickHandler);
        header.addEventListener("click", function (e) {
            e.stopPropagation();
        });
        return header;
    }
    headerDoubleClickHandler(e) {
        let header = e.target.closest(".editor-header");
        header.contentEditable = true;
        header.classList.add("activeHeader");
    }

    footerDoubleClickHandler(e) {
        let footer = e.target.closest(".footer");
        footer.contentEditable = true;
        footer.classList.add("activeFooter");
    }
    getHeaderAndFooterHeight(pageId) {
        var currentPage = this.getPageById(pageId);
        let header = currentPage.getElementsByClassName("editor-header");
        let footer = currentPage.getElementsByClassName("footer");
        return {header: header[0].offsetHeight, footer: footer[0].offsetHeight};
    }
    createFooter() {
        //header
        let innerHtmlTxt = document.getElementsByClassName("footer").length > 0 ? document.getElementsByClassName("footer")[0].innerHTML : "";
        var footer = document.createElement("div");
        // footer.style.padding = "0px 96px"
        footer.classList.add("footer");

        footer.style.marginLeft = "-96px";
        footer.style.marginRight = "-96px";

        var that = this;
        tippy(footer, {
            // 'trigger':'click',
            content: popOverOptions,
            placement: "bottom",
            allowHTML: true,
            interactive: true,
            theme: "light",
            onShown(instance) {
                //setup radio option handlers
                var radios = document.querySelectorAll('input[name="layout"]');
                Array.prototype.forEach.call(radios, (radio) =>
                    radio.addEventListener("change", (e) => {
                        that.changeHeaderFooterLayout(instance, e.target.value);
                    })
                );

                const pickr = Pickr.create({
                    el: document.querySelector(".color-picker-bottom"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickr.on("change", (color, source, instance) => {
                    that.changeBorderColor("bottom", color.toRGBA().toString(0), footer);
                });

                const pickrl = Pickr.create({
                    el: document.querySelector(".color-picker-left"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,
                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrl.on("change", (color, source, instance) => {
                    that.changeBorderColor("left", color.toRGBA().toString(0), footer);
                });

                const pickrt = Pickr.create({
                    el: document.querySelector(".color-picker-top"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,
                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrt.on("change", (color, source, instance) => {
                    that.changeBorderColor("top", color.toRGBA().toString(0), footer);
                });

                const pickrr = Pickr.create({
                    el: document.querySelector(".color-picker-right"),
                    useAsButton: true,

                    theme: "classic", // or 'monolith', or 'nano'
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,
                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrr.on("change", (color, source, instance) => {
                    that.changeBorderColor("right", color.toRGBA().toString(0), footer);
                });

                const pickrbk = Pickr.create({
                    el: document.querySelector(".color-picker-background"),
                    theme: "classic", // or 'monolith', or 'nano'
                    useAsButton: true,
                    components: {
                        // Main components
                        preview: true,
                        opacity: true,
                        hue: true,

                        // Input / output Options
                        interaction: {
                            hex: true,
                            rgba: true,
                            hsla: true,
                            hsva: true,
                            cmyk: true,
                            input: true,
                            clear: true,
                            save: true,
                        },
                    },
                });

                pickrbk.on("change", (color, source, instance) => {
                    that.changeBackgroundColor("right", color.toRGBA().toString(0), footer);
                });
            },
        });

        if (document.getElementsByClassName("footer").length > 0) {
            footer.style.cssText = document.getElementsByClassName("footer")[0].style.cssText;
            footer.innerHTML = innerHtmlTxt;
        } else {
            var div = document.createElement("div");
            div.classList.add("footer--item");
            div.style.minHeight = "20px";
            // div.style.margin = '0px 96px'
            footer.style.minHeight = "60px";
            footer.appendChild(div);
        }
        footer.contentEditable = false;
        footer.addEventListener("dblclick", this.footerDoubleClickHandler);
        footer.addEventListener("click", function (e) {
            e.stopPropagation();
        });
        return footer;
    }

    createNewRow() {
        var pp = document.createElement("div");
        pp.style.minHeight = "20px";
        // pp.style.marginLeft = '96px'
        // pp.style.marginRight = '96px'
        pp.style.background = "pink";
        pp.style.marginTop = "0px";
        pp.style.marginBottom = "0px";
        pp.style.background = "";
        return pp;
    }

    createNewPage(pageId, empty) {
        var page = document.createElement("div");
        var totalPages = this.getPageCount();
        page.classList.add("neo-page");
        page.style.border = "solid 1px #d0d0d0";
        page.style.paddingLeft = "96px";
        page.style.paddingRight = "96px";
        page.style.height = "1123px";
        page.style.display = "flex";

        page.style.background = "#ffffff";
        page.style.marginBottom = "20px";

        //attach header to page
        var header = this.createHeader();
        page.appendChild(header);

        // create content area
        var contentArea = document.createElement("div");
        contentArea.style.flexGrow = 1;
        contentArea.classList.add("content--area");
        // contentArea.style.background ='cyan'

        if (!empty) {
            //create empty div
            var pp = document.createElement("div");

            if (!pageId) pp.classList.add(`page--${totalPages + 1}`);
            else pp.classList.add(pageId);

            pp.style.minHeight = "20px";
            // pp.style.marginLeft = '96px'
            // pp.style.marginRight = '96px'
            pp.style.background = "pink";
            pp.style.marginTop = "0px";
            pp.style.marginBottom = "0px";
            pp.style.background = "";
            contentArea.appendChild(pp);
        }

        page.appendChild(contentArea);
        //create a footer
        var footer = this.createFooter();
        page.appendChild(footer);

        //Emit new page creation event
        const pagreCreatedEvent = new CustomEvent("page-created", {
            detail: {
                page: page,
            },
        });
        document.dispatchEvent(pagreCreatedEvent);
        return page;
    }

    toggleCmd(type, args) {
        let that = this;
        let range = this.prevCursorSelection;
        let text = range ? range.toString() : "";

        // identify when this case hits??
        if (!range) {
            let element = that.selectedElement;
            if (!element) return;
            args.style.forEach(function (styl) {
                if (styl.key == "text-align") {
                    if (styl.value == "center") {
                        element.style.setProperty("float", "none");
                    }
                    styl.key = styl.value == "center" ? "margin" : "float";
                    styl.value = styl.value == "center" ? "auto" : styl.value;
                }
                element.style.setProperty(styl.key, styl.value, styl.key == "background" ? "important" : "");
            });
            return;
        }
        // Whne the cursor is just on top of some node & no range is selected by mouse
        if (range.collapsed) {
            let elem = null;

            // Assuming `that.selectedElement` gives the node on which cursor is placed, or node like images, tables?..
            if (that.selectedElement) {
                elem = that.selectedElement;
            } else {
                // The range has selected only text nodes
                if (this.prevCursorSelection.commonAncestorContainer.nodeName == "#text") {
                    // If the text nodes belong to a list node
                    if (this.prevCursorSelection.commonAncestorContainer.parentNode.nodeName == "LI") {
                        elem = this.prevCursorSelection.commonAncestorContainer.parentNode.parentNode;
                    } else {
                        // If the text nodes belong to a span node
                        // FIX THIS LOGIC: IT WILL BREAK ---
                        elem =
                            this.prevCursorSelection.commonAncestorContainer.parentNode.nodeName == "SPAN"
                                ? // Get the div node of span
                                  this.prevCursorSelection.commonAncestorContainer.parentNode.parentNode
                                : // Get the div parent node of nodes
                                  this.prevCursorSelection.commonAncestorContainer.parentNode;
                    }
                } else {
                    // If the selection is not of type text nodes, make watever is selected the active element
                    elem = this.prevCursorSelection.commonAncestorContainer;
                }
            }
            if (args) {
                // If some style argument has been passed
                args.style.forEach(function (styl) {
                    if (that.selectedElement || elem.nodeName == "UL") styl.key = styl.key == "text-align" || elem.nodeName == "UL" ? "float" : styl.key;

                    if (elem.style.getPropertyValue(styl.key) && styl.key != "background" && styl.key != "color" && styl.key != "font-size") {
                        elem.style.removeProperty(styl.key);
                    } else {
                        elem.style.setProperty(styl.key, styl.value, styl.key == "background" ? "important" : "");
                    }
                });
            }
            // else{
            //     if(elem.className.indexOf(type)==-1){
            //         elem.classList.add(type);
            //     }else{
            //         if(range.commonAncestorContainer.parentNode.tagName=="SPAN"){
            //             let innerHtml = elem.parentNode.innerHTML;
            //             elem.parentNode.innerHTML= innerHtml.replace(/<\/?span[^>]*>/g,"")
            //         }else{
            //             range.commonAncestorContainer.parentNode.className = range.commonAncestorContainer.parentNode.classList[0]
            //         }
            //     }
            // }
        } else {
            // If something has been selected by a mouse & active range exists

            if (range.commonAncestorContainer.parentNode.tagName == "SPAN") {
                if (args) {
                    args.style.forEach(function (styl) {
                        let elem = range.commonAncestorContainer.parentNode;
                        if (elem.style.getPropertyValue(styl.key) && styl.key != "background" && styl.key != "color" && styl.key != "font-size") {
                            elem.style.removeProperty(styl.key);
                            if (elem.getAttribute("style") == "") {
                                elem.parentNode.innerHTML = elem.parentNode.innerHTML.replace(/<\/?span[^>]*>/g, "");
                            }
                        } else {
                            elem.style.setProperty(styl.key, styl.value, styl.key == "background" ? "important" : "");
                        }
                    });
                } else {
                    if (Array.from(range.commonAncestorContainer.parentNode.classList).indexOf(type) != -1) {
                        range.commonAncestorContainer.parentNode.classList.remove(type);
                    } else {
                        range.commonAncestorContainer.parentNode.classList.add(type);
                    }
                }
            } else {
                let pageId = this.getActivePageId();
                let rootParent = range.commonAncestorContainer.parentElement.closest(".editor-header") ? range.commonAncestorContainer.parentNode.parentNode : range.commonAncestorContainer.nodeType == 3 ? range.commonAncestorContainer.parentNode : range.commonAncestorContainer;

                var startIndex = this.getChildNodeIndex(rootParent, range.startContainer.parentNode.nodeName == "SPAN" ? range.startContainer.parentNode.parentNode : range.startContainer.parentNode);
                var endIndex = this.getChildNodeIndex(rootParent, range.endContainer.parentNode.nodeName == "SPAN" ? range.endContainer.parentNode.parentNode : range.endContainer.parentNode);

                if (startIndex == endIndex) {
                    let selectedLength = that.prevCursorSelection.commonAncestorContainer.nodeName == "#text" ? that.prevCursorSelection.commonAncestorContainer.wholeText.length : that.prevCursorSelection.commonAncestorContainer.textContent.length;

                    if (args && args.style[0].key == "background" && (that.prevCursorSelection.commonAncestorContainer.nodeName != "#text" || that.prevCursorSelection.endOffset - that.prevCursorSelection.startOffset == selectedLength)) {
                        let elem = that.prevCursorSelection.commonAncestorContainer.parentNode;
                        args.style.forEach(function (styl) {
                            elem.style.setProperty(styl.key, styl.value, styl.key == "background" ? "important" : "");
                        });
                        return;
                    }
                    range.deleteContents();
                    let tag = document.createElement("span");
                    if (args) {
                        args.style.forEach(function (styl) {
                            tag.style[styl.key] = styl.value;
                        });
                    } else {
                        tag.classList.add(type);
                    }
                    tag.innerText = text;
                    range.insertNode(tag);
                } else {
                    for (let i = startIndex; i <= endIndex; i++) {
                        let elem = rootParent.children[i];
                        let innerText = elem.innerHTML;
                        if (elem.children.length > 0 && range.commonAncestorContainer.childNodes[i].children[0].tagName == "SPAN") {
                            if (args) {
                                args.style.forEach(function (styl) {
                                    if (elem.children[0].style.getPropertyValue(styl.key) && styl.key != "background" && styl.key != "color" && styl.key != "font-size") {
                                        elem.children[0].style.removeProperty(styl.key);
                                        if (elem.children[0].getAttribute("style") == "") {
                                            elem.innerHTML = innerText.replace(/<\/?span[^>]*>/g, "");
                                        }
                                    } else {
                                        elem.children[0].style[styl.key] = styl.value;
                                    }
                                });
                            }
                            // else{
                            //     if(elem.children[0].className.indexOf(type)!=-1 && elem.children[0].style.length<1){
                            //         elem.innerHTML = innerText.replace(/<\/?span[^>]*>/g,"");
                            //         return;
                            //     }
                            //     elem.children[0].classList.add(type);
                            // }
                        } else {
                            let htmlTxt = "<span style='";
                            if (args) {
                                args.style.forEach(function (styl) {
                                    htmlTxt = htmlTxt + styl.key + ":" + styl.value + ";";
                                });
                                htmlTxt = htmlTxt + "'/>";
                            }
                            htmlTxt = htmlTxt + elem.innerText + "</span>";
                            elem.innerHTML = htmlTxt;
                            // elem.appendChild(tag);
                        }
                    }
                    let selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
                    selection.removeAllRanges();
                    this.prevCursorSelection = null;
                }
            }
        }
    }

    handleImageResize() {
        if (!this.selectedElement) return;
        let pageId = this.getActivePageId(this.selectedElement);
        if (this.checkNeedsPageBreak(pageId)) {
            this.shiftContentToNextPages(pageId);
        }
    }

    fitImg() {
        let element = this.selectedElement;
        if (element && element.className == "resizable") {
            element.style.width = "100%";
            element.style.height = "auto";
            element.style.resize = "vertical";
            //To avoid blank space in footer and header
            element.style.marginBottom = "-10px";
            if (element.parentNode.closest(".footer--item")) {
                element.parentNode.closest(".footer--item").style.position = "absolute";
                element.parentNode.closest(".footer--item").style.bottom = "0";
                element.parentNode.closest(".footer--item").style.width = "100%";
            }
        }
    }

    toggleMargins() {
        let parent = null;
        if (this.prevCursorSelection) {
            if (this.prevCursorSelection["commonAncestorContainer"].tagName == "TH" || this.prevCursorSelection["commonAncestorContainer"].tagName == "TD") {
                parent = this.prevCursorSelection["commonAncestorContainer"].offsetParent.parentNode;
            } else {
                parent = this.prevCursorSelection["commonAncestorContainer"]["parentElement"].tagName == "DIV" ? this.prevCursorSelection["commonAncestorContainer"]["parentElement"] : this.prevCursorSelection["commonAncestorContainer"]["parentElement"]["parentElement"];
            }
        } else {
            parent = this.selectedElement.className == "resizable" ? this.selectedElement.parentElement : this.selectedElement;
        }
        if (parent.className != "neo-page")
            // parent.style.margin = parent.style.marginLeft != "0px" ? "0px" : "0px 96px";
            parent.style.margin = parent.style.marginLeft != "0px" ? "0px" : "0px -96px";
    }

    addImage(image_url) {
        var pageId = this.getActivePageId();
        var row = this.getActiveNode();
        let range = this.prevCursorSelection;
        let imgWrap = document.createElement("div");
        let img = document.createElement("img");

        img.onload = () => {
            let pageId = this.getActivePageId(imgWrap);
            imgWrap.style.height = `${this.getNodeHeight(imgWrap)}px`;
            if (this.isNodeInTable(range)) {
                this.handleTableSplt(pageId);
            }
            if (this.checkNeedsPageBreak(pageId)) {
                this.shiftContentToNextPages(pageId);
            }
        };

        img.src = image_url;
        img.setAttribute("draggable", "false");
        imgWrap.style.height = "auto";
        imgWrap.style.width = "200px";

        // To avoid the right space in img case
        // imgWrap.style.marginRight = "15px";
        imgWrap.classList.add("resizable");
        imgWrap.setAttribute("contenteditable", false);
        imgWrap.appendChild(img);
        if (range.commonAncestorContainer.nodeName == "SPAN") {
            range.commonAncestorContainer.parentNode.appendChild(imgWrap);
        } else {
            if (!this.isNodeInTable(range)) {
                var emptyRow = this.createNewRow();
                emptyRow.classList.add(pageId);
                range.insertNode(imgWrap);

                //Add empty row after image
                this.insertAfterNodeInPage(emptyRow, imgWrap.parentNode);
                this.focusCursor(emptyRow);
            } else {
                range.insertNode(imgWrap);
            }
        }

        // let that = this;
        // setTimeout(function(){
        //     let pageId = that.getActivePageId(imgWrap);
        //     // that.handleTableSplt();
        //     if(that.checkNeedsPageBreak(pageId)){
        //         that.shiftContentToNextPages(pageId)
        //     }
        // },50)
        new ResizeObserver(this.handleImageResize.bind(this)).observe(imgWrap);
    }

    applyHeaderClass(items, clsName) {
        [...items].map((e) => e.classList.add(clsName));
    }

    getRange(sel) {
        sel = sel || window.getSelection();
        return sel.rangeCount > 0 ? sel.getRangeAt(0) : null;
    }

    isNodeInTable(node) {
        return node.commonAncestorContainer.nodeName === "TD";
    }

    applyTextTransform(text) {
        text.style.setProperty("text-transform");
    }

    _getSelectionRecursively(node, TObjects) {
        if (["BR"].includes(node.nodeName)) return; // Do nothing with these nodes
        if (node.nodeName === "#text") {
            var nn = {node: node};
            if (node.parentNode.nodeName === "SPAN") {
                nn.spanned = true;
            }
            TObjects.push(nn);
        }
        if (node.childNodes) {
            [...node.childNodes].forEach((cn) => {
                this._getSelectionRecursively(cn, TObjects);
            });
        }
        return TObjects;
    }

    /**
     * Get all text nodes present in a selection, recusrively traverse dom & return all text Nodes,
     * Keep track of partial selection of nodes.
     */
    getTextSelection() {
        var range = this.getRange();

        let node = range.startContainer;
        let endNode = range.endContainer;

        node = node.parentNode.closest("div");
        endNode = endNode.parentNode.closest("div");

        var _TObjects = [];

        if (node === endNode) {
            // 1. If start & end Nodes are same!
            // Get text nodes in all child nodes
            [...node.childNodes].forEach((cn) => {
                this._getSelectionRecursively(node, _TObjects);
            });
        } else {
            // 2. Otherwise , loop until 2nd last node to,
            // get all text nodes in each  nodes
            while (node && node !== endNode) {
                [...node.childNodes].forEach((cn) => {
                    // text or span
                    this._getSelectionRecursively(cn, _TObjects);
                });
                node = node.nextSibling;
            }

            // 3. Process the last remaining node
            var objs = [];
            [...node.childNodes].forEach((cn) => {
                this._getSelectionRecursively(cn, objs);
            });

            _TObjects = _TObjects.concat(objs);
        }

        var indexOfStart = _TObjects.findIndex((e) => {
            return e.node === range.startContainer;
        });

        var indexOfend = _TObjects.findIndex((e) => {
            return e.node === range.endContainer;
        });

        _TObjects = _TObjects.slice(indexOfStart, _TObjects.length);
        _TObjects = _TObjects.slice(0, indexOfend + 1);

        //Check partial for the last nodes in selection?
        if (range.startOffset) {
            _TObjects[0].start = range.startOffset;
        }

        if (range.endOffset) {
            _TObjects[_TObjects.length - 1].end = range.endOffset;
        }
        return _TObjects;
    }

    _textTransForm(node, transfromFunc) {
        if (node.nodeName === "#text") {
            //if it has child nodes, its most probably a span or a div
            //Check if this span text is partof previous text, if so do not apply transformFunc
            var shouldApplyTransfrom = true;
            if (node.parentNode && node.parentNode.previousSibling) {
                if (node.parentNode.previousSibling.nodeName === "#text" && node.parentNode.previousSibling.textContent[node.parentNode.previousSibling.textContent.length - 1] !== String.fromCharCode(160)) {
                    shouldApplyTransfrom = false;
                } else {
                    //Assume its a span maybe??
                    if (node.parentNode.previousSibling.lastChild.textContent[node.parentNode.previousSibling.lastChild.textContent.length - 1] !== String.fromCharCode(160)) shouldApplyTransfrom = false;
                }
            }
            if (shouldApplyTransfrom)
                // this._textTransForm(cn, transfromFunc)
                node.replaceWith(transfromFunc(node.textContent));
        } else {
            if (node.childNodes.length >= 1) {
                [...node.childNodes].forEach((cn) => {
                    this._textTransForm(cn, transfromFunc);
                });
            }
        }
    }

    toUpperCase(str) {
        return str.toUpperCase();
    }

    toLowerCase(str) {
        return str.toLowerCase();
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    _applyTextTransform(node, transfromFunc) {
        //partiall selected Nodes
        var newText = null;

        // 1. When only one line is selected, it will have both start & end offset
        if (node.start && node.end) {
            var textBeforeSelection = node.node.textContent.slice(0, node.start);
            var selectedText = node.node.textContent.slice(node.start, node.end);
            var textAfterSelection = node.node.textContent.slice(node.end, node.node.textContent.length);
            newText = textBeforeSelection + transfromFunc(selectedText) + textAfterSelection;
            node.node.replaceWith(newText);
        }

        if (node.start) {
            var part1 = node.node.textContent.slice(0, node.start);
            var part2 = node.node.textContent.slice(node.start, node.node.textContent.length);
            newText = part1 + transfromFunc(part2);
            node.node.replaceWith(newText);
        }
        if (node.end) {
            var part1 = node.node.textContent.slice(0, node.end);
            var part2 = node.node.textContent.slice(node.end, node.node.textContent.length);
            newText = transfromFunc(part1) + part2;
            node.node.replaceWith(newText);
        } else {
            newText = transfromFunc(node.node.textContent);
            node.node.replaceWith(newText);
        }

        return newText;
    }

    textTransformCase(transfromType) {
        var textNodes = this.getTextSelection();

        var transfromFunc = null;

        var newSelection = {};

        if (transfromType === "capitalize") transfromFunc = this.toTitleCase;
        if (transfromType === "uppercase") transfromFunc = this.toUpperCase;
        if (transfromType === "lowercase") transfromFunc = this.toLowerCase;

        for (var i = 0; i < textNodes.length; i++) {
            var node = textNodes[i];
            var newNode = this._applyTextTransform(node, transfromFunc);

            // if(i === 0){
            //     newSelection.start = newNode
            // }

            // if(i === textNodes.length-1){
            //     newSelection.end = newNode
            // }
        }

        //Restore range selection.
        // let range = new Range()
        // // range.setStart(textNodes[0].node,textNodes[0].start ?? 0)
        // // range.setEnd(textNodes[textNodes.length-1].node,textNodes[textNodes.length-1].end ?? 0 )

        // range.setStart(newSelection.start, 0)
        // range.setEnd(newSelection.end,2 )

        // document.getSelection().removeAllRanges()
        // document.getSelection().addRange(range)
    }

    _applyTextProperty(node, property, value) {
        if (node.spanned && node.node.parentNode.style.getPropertyValue(property)) {
            // Node is already in a span & if the property is already set
            node.node.parentNode.style.setProperty(property, value);
        } else {
            var span = document.createElement("span");
            var clone = node.node.cloneNode(true);
            span.appendChild(clone);
            span.style.setProperty(property, value);
            node.node.replaceWith(span);
        }
    }
    /**
     * Gets the selected texts & sets the required property on those.
     */
    setTextProperty(property, value) {
        if (this.getActiveNode().closest("TD")) {
            document.execCommand("styleWithCSS", true, null);
            document.execCommand("bold", true, value);
            return;
        }
        var textNodes = this.getTextSelection();
        textNodes.forEach((node) => {
            this._applyTextProperty(node, property, value);
        });
    }

    applyTextDecoration(type) {
        // var nodes = this.getTextSelection()
        if (type === "foreColor" || type === "backColor") {
            const pickr = Pickr.create({
                el: `.${type}`,
                theme: "classic", // or 'monolith', or 'nano'
                padding: 24,
                useAsButton: false,
                appClass: "custom-class-backColor",

                swatches: ["rgba(244, 67, 54, 1)", "rgba(233, 30, 99, 0.95)", "rgba(156, 39, 176, 0.9)", "rgba(103, 58, 183, 0.85)", "rgba(63, 81, 181, 0.8)", "rgba(33, 150, 243, 0.75)", "rgba(3, 169, 244, 0.7)", "rgba(0, 188, 212, 0.7)", "rgba(0, 150, 136, 0.75)", "rgba(76, 175, 80, 0.8)", "rgba(139, 195, 74, 0.85)", "rgba(205, 220, 57, 0.9)", "rgba(255, 235, 59, 0.95)", "rgba(255, 193, 7, 1)"],

                components: {
                    // Main components
                    preview: true,
                    opacity: true,
                    hue: true,

                    // Input / output Options
                    interaction: {
                        hex: true,
                        rgba: true,
                        hsla: true,
                        hsva: true,
                        cmyk: true,
                        input: true,
                        clear: true,
                        save: true,
                    },
                },
            });

            pickr
                .on("change", (color, source, instance) => {
                    if (instance.options.el.className === "foreColor") {
                        document.execCommand("foreColor", false, color.toRGBA().toString(0));
                    }
                    if (instance.options.el.className === "backColor") {
                        document.execCommand("styleWithCss", true, true);
                        document.execCommand("backColor", false, color.toRGBA().toString(0));
                        if (this.getActiveNode().closest("td")) {
                            // this.tableBgColor=color
                            if (this.tableBgColor) {
                                this.getActiveNode().closest("td").style.background = this.tableBgColor;
                            }
                        }
                        [...document.querySelectorAll(".neo-page")].forEach((page) => (page.style.background = "white"));
                    }
                })
                .on("cancel", (instance) => {});
            return;
        }
        document.execCommand(type);
        // this.nodes.forEach(n=>{
        //     var span = document.createElement("span")
        //     span.appendChild
        // })
    }

    isCursorInTable(range) {
        return range.startContainer.parentNode.nodeName === "TD" || range.startContainer.closest("TD");
    }

    getNodes(sel, preserveSelectedNodes) {
        var range = this.getRange(sel);
        if (!range) return [];
        var node = range.startContainer;
        var endNode = range.endContainer;
        var nodes = [];

        if (node === endNode) {
            // When only one node is selected & is inside table cell
            if (node.nodeName === "#text" && node.parentNode.nodeName === "TD") {
                var span = document.createElement("span");
                var clonedNode = node.cloneNode(true);
                node.parentNode.removeChild(node);
                span.appendChild(clonedNode);
                return [span];
            } else if (node.nodeName === "#text") {
                return [node.parentNode.closest("div")];
            } else return [node];
        }

        var brNodes = [];

        if (node.parentNode.nodeName === "TD" && endNode.parentNode.nodeName === "TD") {
            var span = document.createElement("span");

            while (node && node != endNode) {
                if (node.nodeName !== "BR") {
                    var clonedNode = node.cloneNode(true);
                    span.appendChild(clonedNode);
                } else {
                    brNodes.push(node);
                    nodes.push(span);
                    span = document.createElement("span");
                }
                var n = node.nextSibling || node.nextElementSibling;
                if (node.nodeName !== "BR") node.parentNode.removeChild(node);
                node = n;
            }
            nodes.push(endNode);

            brNodes.forEach((e) => e.parentNode.removeChild(e));

            // Remove the br tags here, as it all br tages will aggregate at the end of loop
            // and empty space will be there in table after list
        } else {
            node = node.parentNode.closest("div");
            endNode = endNode.parentNode.closest("div");
            while (node && node != endNode) {
                nodes.push(node);
                var n = node.nextSibling;
                if (!preserveSelectedNodes) node.parentNode.removeChild(node);
                node = n;
            }

            // FIX THIS::
            nodes.push(endNode);
            if (!preserveSelectedNodes) endNode.parentNode.removeChild(endNode);
        }
        return nodes;
    }

    /**
     *
     */
    setTextStyle(style) {
        // Get text Nodes
        // var textNodes = this.getNodes(null,true) // true means do not remove nodes from DOM
        // textNodes.forEach(node => {
        //     if(node.nodeName === "#text")
        //         var span = document.createElement("span")
        //         span.style.setProperty(style.type, style.value)
        //         span.innerText = "xx"
        //         node.innerHTML = span.innerHTML
        // })
    }

    setupIconClickListener(node) {
        //highlight on click

        node.addEventListener("mouseover", (e) => {
            e.target.style.setProperty("border", "solid 2px goldenrod");

            if (!e.target.style.getPropertyValue("border-radius")) {
                e.target.style.setProperty("border-radius", "4px");
                // this.selectableObject.style.setProperty("border-radius","4px")
            }
        });

        node.addEventListener("mouseleave", (e) => {
            if (this.selectableObject !== e.target) {
                e.target.style.removeProperty("border");
            }
        });

        node.addEventListener("click", (e) => {
            if (e.target.nodeName === "IMG") {
                this.selectableObject = e.target;
            } else {
                // Make it currently selectable object ?? this can be an array of objects later on.
                this.selectableObject = e.target.ownerSVGElement;
            }

            this.selectableObject.style.setProperty("border", "solid 2px #bd8700");
            if (!this.selectableObject.style.getPropertyValue("border-radius")) {
                this.selectableObject.style.setProperty("border-radius", "4px");
            }
        });
    }

    /**
     * Changes the size of the object, based on what type of object is selected currently
     *
     */
    changeSize(size) {
        // Currently size change of icons are handled by this function , to change size using old function,
        // create a proxy to that function
        var pageId = this.getActivePageId();
        if (this.selectableObject) {
            if (this.selectableObject.nodeName === "svg") {
                this.changeImageSize(this.selectableObject, size);
            }
            if (this.selectableObject.nodeName === "IMG") {
                this.changeImageSize(this.selectableObject, size);
            }
        } else {
            // this.toggleCmd(size+"px", {style : [{key : "font-size", value : size+"px"}]});
            //https://stackoverflow.com/questions/5868295/document-execcommand-fontsize-in-pixels
            document.execCommand("styleWithCSS", 0, false);
            document.execCommand("fontSize", false, 7);
            let fontElements = document.getElementsByTagName("font");
            fontElements.forEach((ele) => {
                if (ele.getAttribute("size")) {
                    ele?.removeAttribute("size");
                    ele.style.fontSize = `${size}px`;
                }
            });
        }

        this.handleTableSplt(pageId);
        var currentPageId = this.getActivePageId();
        this.shiftContentToNextPages(currentPageId);
    }

    changeImageSize(image, size) {
        // ...

        image.style.width = image.style.height = `${size}px`;
    }

    _wrapNode(target, wrapper) {
        var wrapper = wrapper || document.createElement("div");
        // wrapper.appendChild(target)
        target.parentNode.insertBefore(wrapper, target);
        wrapper.appendChild(target);
        return wrapper;
    }

    // addListPlaceHolderEventListener(span, select){
    //     span.addEventListener('click', (e)=>{
    //         var id = e.target.getAttribute('data-select-id')
    //         this.listPlaceHolders[id].style.display = 'block';
    //         this.listPlaceHolders[id].style.top = e.clientY
    //         this.listPlaceHolders[id].style.left = e.clientX
    //     })

    //     select.addEventListener('change', (e)=>{
    //         var val = e.target.value
    //         var span = document.querySelector(`span[data-select-id="${this.activeListPlaceHolderId}"]`)
    //         span.firstChild.textContent = val;
    //     })
    // }

    // addListPlaceholder(data){

    //     // var range = this.getRange()
    //     var activeNode = this.activeEditorNode

    //     var select = document.createElement("select")

    //     var span = document.createElement("span")
    //     var guid = this.guidGenerator()
    //     span.setAttribute('data-select-id', guid)

    //     span.innerText = data.name
    //     span.style.border = 'dashed 1px #8c836d';
    //     span.style.padding = '4px';

    //     data.values.split("\n").forEach(v => {
    //         var op = document.createElement("option")
    //         op.text = v
    //         op.value = v
    //         select.appendChild(op)
    //     })

    //     select.setAttribute('data-select-id', guid)
    //     this.listPlaceHolders[guid] = select
    //     // span.appendChild(select)
    //     document.body.appendChild(select)
    //     span.style.position ='relative'
    //     select.style.display = 'none'
    //     select.style.position = 'absolute'
    //     select.style.border = 'none'
    //     select.style.top = '0px'

    //     this.addListPlaceHolderEventListener(span, select)
    //     this.activeListPlaceHolderId = guid
    //     // modal.hide()
    //     // range.insertNode(select)
    //     activeNode.appendChild(span)

    // }

    addIcon(icon, iconType = null, iconBackgroundColor = "cyan", svgTransform = true) {
        var range = this.getRange();
        var iconObj = document.createElement("img");
        iconObj.width = 30;
        iconObj.height = 30;
        iconObj.src = icon;
        iconObj.className = "neo--report--icons";

        var br = document.createElement("br");
        range.insertNode(iconObj);

        if (!svgTransform) {
            // let imgWrap = document.createElement('div');
            // // let img = document.createElement('img');
            // // img.src = this.selectedSocialIcon;
            // iconObj.setAttribute("draggable", "false")
            // iconObj.style.height = "auto";
            // iconObj.style.width = "60px";
            // iconObj.classList.add("resizable")
            // iconObj.setAttribute("contenteditable", false);
            // imgWrap.appendChild(iconObj)
            // range.insertNode(imgWrap)

            // range.insertNode(imgWrap);

            this.setupIconClickListener(iconObj);
            return;
        }

        // SVG transform

        SVGInject(iconObj);

        var range = this.getRange();

        setTimeout(() => {
            [...document.querySelectorAll(".neo--report--icons")].forEach((ico) => {
                // this.setupIconClickListener(ico)
                // var wrapped = this._wrapNode(ico, document.createElement("div"))

                if (!ico.style.getPropertyValue("border-radius")) {
                    if (iconType) {
                        if (iconType === "squareIcon") {
                            ico.style.background = iconBackgroundColor;
                            ico.style.padding = "4px";
                            ico.style.borderRadius = "4px";
                        }
                        if (iconType === "roundIcon") {
                            ico.style.background = iconBackgroundColor;
                            ico.style.padding = "4px";
                            ico.style.borderRadius = "50%";
                        }
                    }
                }

                this.setupIconClickListener(ico);
                // if(!ico.nextSibling){
                this.insertAfterNodeInPage(document.createTextNode("\u00A0"), ico);

                // }
            });
            // var svg = range.commonAncestorContainer.querySelector('svg').cloneNode(true)
            // range.commonAncestorContainer.querySelector('svg').parentNode.removeChild(range.commonAncestorContainer.querySelector('svg'))
            // var div = document.createElement("span")
            // div.appendChild(svg)
            // range.deleteContents()
            // range.insertNode(div)
        }, 2000);
    }

    changeColor(color, type, border) {
        // If an icon is currently selected
        if (this.selectableObject && this.selectableObject.nodeName === "svg") {
            this.selectableObject.style.fill = color;
        }
        // Remove this crap, below & Handle it nicelY!!!!!!!!!!!!!!!!!
        else if (type == "text") {
            // this.textColor = e.hex;
            // this.toggleCmd(color, {style : [{key : "color", value : color}]})
            document.execCommand("foreColor", false, color);
        } else if (type == "bg") {
            // this.bgColor = color;
            // this.toggleCmd(color, { style : [{key : "background", value : color}] } )
            var activeNode = this.getActiveNode();

            if (this.selectableObject) {
                this.selectableObject.style.fill = color;
            } else if (activeNode.closest("td")) {
                // this.tableBgColor=color
                activeNode.closest("td").style.background = color;
            } else {
            }
        } else if (type == "table_border") {
            let activeTable = document.querySelector("[table-selected='true']");
            activeTable.childNodes[0].childNodes.forEach((n) => n.childNodes.forEach((n1) => (n1.style.borderColor = color)));
        }
    }

    setRange(range) {
        const {startContainer, endContainer, endOffset, startOffset} = range;
        range.setStart(startContainer, startOffset);
        range.setEnd(endContainer, endOffset);
    }

    textAlign(type) {
        if (this.selectableObject) {
            // Some selectable object has been highlighted, Give priority to this & return
            if (type === "center") {
                // this.selectableObject.style.textAlign = 'center'
                this.selectableObject.style.float = "none";
                if (!this.selectableObject.closest("td")) {
                    this.selectableObject.parentNode.style.display = "flex";
                    this.selectableObject.parentNode.style.justifyContent = "center";
                } else {
                    this.selectableObject.closest("td").style.textAlign = "center";
                }
            } else {
                this.selectableObject.parentNode.style.removeProperty("display");
                this.selectableObject.style.float = type;
            }
            return;
        }
        // NOTE: This is done to keep old functionality working, change it only when all code has been refactored..
        // Proxy to old function in case of images
        if (this.selectedElement && [...this.selectedElement.classList].includes("resizable")) {
            this.toggleCmd(type, {
                style: [
                    {key: "text-align", value: type},
                    {key: "display", value: "block"},
                ],
            });
        }

        // NEW CODE
        else {
            var range = window.getSelection().getRangeAt(0);
            if (range.collapsed) return;

            if (type === "justify") {
                // document.execCommand("justifyFull")
                if (this.getActiveNode().closest("TD")) {
                    this.getActiveNode().closest("TD").style.setProperty("text-align", type);
                    return;
                }
                var node = range.startContainer;
                var endNode = range.endContainer;
                var page = this.getActivePageId();
                var nodes = this.getNodes();
                let html = "";
                if (node.nodeName != "#text" && endNode.nodeName != "#text") {
                    //To check if it's already aligned or not, if aligned return
                    // this.setRange(range);
                    node.style.textAlign = "justify";
                    window.getSelection().removeAllRanges();
                    window.getSelection().empty();
                    return;
                }
                // return;
                var div = document.createElement("div");
                // var pageId = this.getIdFromPage(page)
                div.classList.add(page);
                if (node.parentNode && node.parentNode.className.includes("placeholder-custom")) {
                    div.classList.add("placeholder-custom");
                }
                //   var nodes = this.getNodes();
                nodes.forEach((n) => {
                    n.removeAttribute?.("class");
                    html = html + (n.innerHTML ?? n.textContent ?? "");
                    html = html.replace("<br>", "");
                });
                div.innerHTML = html;

                div.style.textAlign = "justify";
                range.deleteContents();
                if (node && node.parentNode) {
                    const pageParentNode = node.parentNode;
                    pageParentNode.parentNode ? pageParentNode.parentNode.removeChild(pageParentNode) : node.parentNode.removeChild(node);
                }
                range.insertNode(div);
                window.getSelection().removeAllRanges();
                window.getSelection().empty();
            } else {
                // If aligning is done inside table cells, its easy, apply align & return early.
                if (this.getActiveNode().closest("TD")) {
                    this.getActiveNode().closest("TD").style.setProperty("text-align", type);
                    return;
                }
                var nodes = this.getNodes(null, true);
                nodes.forEach((node) => {
                    node.style.textAlign = type == "center" ? type : type == "right" ? "end" : "start";
                });
                //   window.getSelection().empty();
            }
        }
    }
    addList(type, listType) {
        // NOTE: When shift is pressed and eneter is pressed text nodes are inserted into one div itself & new rows are not created
        var range = window.getSelection().getRangeAt(0);
        if (range.collapsed) return;
        var currentPageId = this.getActivePageId();

        var node = range.startContainer;
        var endNode = range.endContainer;
        const {endOffset, startOffset} = range;

        // Check is its already a list
        if (range.commonAncestorContainer.nodeName === "UL" || range.commonAncestorContainer.parentNode.closest("ul")) {
            var childNodes = range.commonAncestorContainer.nodeName === "UL" ? [...range.commonAncestorContainer.childNodes] : [...range.commonAncestorContainer.parentNode.closest("ul").childNodes];
            childNodes.forEach((li) => li.style.setProperty("list-style-type", listType));
            return;
        }
        var nodes = this.getNodes();
        var ul = document.createElement("ul");

        var randomId = this.guidGenerator();
        ul.setAttribute("data-uid", randomId);

        nodes.forEach((e) => {
            var li = document.createElement("li");
            li.style.setProperty("list-style-type", listType);
            e.classList && e.classList.remove(currentPageId);
            li.appendChild(e);
            ul.appendChild(li);
        });

        /*

        When a list of text is converted to list , Check if the text nodes are inside table cell,
        -> if inside table cell, insert directly the UL tag or
        -> insert UL inside a div TAG.

        */
        if (!this.isCursorInTable(range)) {
            var row = this.createNewRow();
            row.classList.add(currentPageId);
            row.appendChild(ul);
            range.insertNode(row);
        } else {
            range.insertNode(ul);
        }

        // Clear selected Range
        range.setStart(node, startOffset);
        range.setEnd(endNode, endOffset);
        window.getSelection().addRange(range);
    }

    getChildNodeIndex(parent, child) {
        return Array.prototype.indexOf.call(parent.children, child);
    }

    getPageById(pageId) {
        /* return an existing page in the documnent */
        return document.getElementById(pageId);
    }
    getPageCount() {
        /* Return total no of pages in the editor
         */
        return document.querySelectorAll(this.PAGE_IDENTIFIER).length;
    }

    addToPage(page, node) {
        // maybe this can be cached? check later while optmizing
        var contentArea = page.querySelector(`#${page.id}>.content--area`);
        contentArea.appendChild(node);
    }

    prependInPage(page, nodes) {
        /* Given a list of nodes, prepend it to the start of the page
         */
        let regex = new RegExp("page--[0-9]+");
        for (var i = nodes.length - 1; i >= 0; i--) {
            nodes[i].className = nodes[i].className.replace(regex, page.id);
            // nodes[i].classList.add(page.id)
            page.querySelector(".content--area").insertBefore(nodes[i], page.querySelector(".content--area").firstElementChild);

            // page.firstElementChild.parentNode.insertBefore(nodes[i], page.firstElementChild.nextSibling)
        }
    }

    getRootParent(range) {
        if (range.commonAncestorContainer.className && range.commonAncestorContainer.className.indexOf("content--area") != -1) {
            return range.commonAncestorContainer.parentNode;
        } else {
            return range.commonAncestorContainer;
        }
        // return  range.commonAncestorContainer.className.indexOf("content--area")!=-1 ?
        //         range.commonAncestorContainer :
        //         range.commonAncestorContainer.parentNode
    }

    removePage(pageId) {
        var pp = document.getElementById(pageId);
        pp.parentNode.removeChild(pp);
    }

    isEmpty(node) {
        if ((node.childNodes[0]?.tagName === "BR" && node.childNodes.length === 1) || (node.childNodes[0]?.tagName === "SPAN" && node.childNodes[0].firstChild.innerText === "") || node.childNodes.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    //This removes all the empty pages, after backspace press.
    clearEmptyPages() {
        var pages = this.getPageCount();
        while (pages > 1) {
            var pid = `page--${pages}`;
            if (this.isPageEmpty(pid)) this.removePage(pid);
            else break;
            pages = pages - 1;
        }
    }

    /*
    Checks if two nodes are linked together .ie. part of same node logically ,
     which has been splitted up in DOM
    */
    areNodesLinkedContentType(node1, node2) {
        node1 = node1.childNodes[0];
        node2 = node2.childNodes[0];

        var linkedContentTypes = ["UL", "TABLE"];
        if (node1 && node2 && linkedContentTypes.includes(node1.nodeName) && linkedContentTypes.includes(node2.nodeName) && node1.nodeName === node2.nodeName) {
            if (node1.nodeName === "TABLE") {
                if (node1.getAttribute("data-table-id") === node2.getAttribute("data-table-id")) {
                    return {type: "table"};
                }
            }
            if (node1.nodeName === "UL") {
                if (node1.getAttribute("data-uid") === node2.getAttribute("data-uid")) {
                    return {type: "list"};
                }
            }
        }

        return false;
    }

    /*

    Get the page id in which the given node is located.
    */
    getEnclosingPage(node) {
        return node.closest(".neo-page").id;
    }

    checkEmptyTableRow(tr) {
        var isLastTDEmpty = true;

        var firstRowCells = tr.childNodes;

        firstRowCells.forEach((td) => {
            if (td.childNodes.length >= 1) {
                isLastTDEmpty = false;
            }
        });

        return isLastTDEmpty;
    }
    /*
        Merge rows of second table into first table until page size is reached.
    */
    handleLinkedTable(currentPageId, tab2) {
        //backspack join

        // var currentPageId = this.getActivePageId()
        var regex = /\d+/g;
        var id = parseInt(currentPageId.match(regex)[0]);
        let headerFooterHeight = this.getHeaderAndFooterHeight(currentPageId);

        // check total height of page, can we expand more content here??
        // let currentPageHeight = [...document.querySelectorAll(`.${currentPageId}`)].reduce((a,b) => a+b.offsetHeight, headerFooterHeight.header+headerFooterHeight.footer)
        let currentPageHeight = this.getPageHeight(`page--${id - 1}`);
        var heightDiff = this.pageBreakHeight - currentPageHeight;

        var tab2rows = [...tab2.rows];
        let tab2LastRowHeight = this.getNodeHeight(tab2rows[0]);

        if (this.checkEmptyTableRow(tab2rows[0]) && tab2LastRowHeight > heightDiff) {
            return;
        }
        // var tab2 = firstNodeofNextPage.childNodes[0]
        var tab1 = this.getLinkedTable(`page--${id - 1}`, tab2.getAttribute("data-table-id"), /*prev*/ true);

        var rows = [...tab1.rows];

        while ((tab2rows.length > 0 || heightDiff === 0) && (this.checkEmptyTableRow(tab2rows[0]) ? tab2LastRowHeight < heightDiff : true)) {
            var tr = tab2rows.shift();
            //  For every row find if row needs to be split or moved as a whole
            if (tr.offsetHeight <= heightDiff) {
                if (tab1.rows.length > 0 && tr.getAttribute("data-row-id") === [...tab1.rows][tab1.rows.length - 1].getAttribute("data-row-id")) {
                    // merge splited rows
                    let cells = [...tr.childNodes];
                    let tab1LastRow = [...tab1.rows][tab1.rows.length - 1];
                    let tab1LastRowCells = [...tab1LastRow.childNodes];
                    cells.forEach((cell, cell_idx) => {
                        let childNodes = [...cell.childNodes];
                        let prependNode = null;
                        childNodes.forEach((childNode) => {
                            if (tab1LastRowCells[cell_idx].childNodes.length === 0) {
                                tab1LastRowCells[cell_idx].appendChild(childNode);
                                prependNode = childNode;
                            } else {
                                if (prependNode === null) prependNode = [...tab1LastRowCells[cell_idx].childNodes][0];

                                prependNode.parentNode.appendChild(childNode);
                            }
                        });
                    });

                    if (this.checkEmptyTableRow(tr)) {
                        tr.parentNode.removeChild(tr);
                    }
                } else {
                    tab1.querySelector("tbody").appendChild(tr);
                }
                heightDiff = heightDiff - tr.offsetHeight;
            } else {
                //split here.
                var row_uid = null;

                if (tr.getAttribute("data-row-id")) {
                    row_uid = tr.getAttribute("data-row-id");
                } else {
                    row_uid = this.guidGenerator();
                }

                var newRow = this.getLinkedRow(`page--${id - 1}`, row_uid, [...tr.childNodes], /*prev*/ true);

                // Mark these rows as linked.
                tr.setAttribute("data-row-id", row_uid);

                var cells = [...tr.childNodes];
                cells.forEach((cell, idx) => {
                    var totalNodeHeight = 0;
                    var childNodes = [...cell.childNodes];
                    let previousChildNode = null;
                    var cellsToSplit = [];
                    var requireSplit = null;
                    // let childrenTotalHeight = 0

                    // if(childNodes.length > 0){
                    //     let lastChildNode = childNodes[childNodes.length - 1]

                    //     let lastChildNodeRect = this.getNodeBoundingClientRect(lastChildNode)
                    //     let firstChildNodeRect = this.getNodeBoundingClientRect(childNodes[0])

                    //     childrenTotalHeight = lastChildNodeRect.bottom - firstChildNodeRect.top
                    // }

                    // var childrenTotalHeight = childNodes.reduce((a,b)=> a + this.getNodeHeight(b),0)

                    // let cell_idx = 0
                    while (childNodes.length > 0 && totalNodeHeight < heightDiff) {
                        var firstNodeInCell = childNodes.shift();
                        let _h = 0;
                        if (firstNodeInCell.nodeName === "BR") {
                            if (previousChildNode && previousChildNode.nodeName === "BR") _h = firstNodeInCell.getBoundingClientRect().height;
                        } else {
                            _h = this.getNodeHeight(firstNodeInCell);
                        }

                        if (_h + totalNodeHeight > heightDiff || firstNodeInCell === undefined) break;

                        var _cell = newRow.childNodes[idx];
                        _cell.appendChild(firstNodeInCell);
                        totalNodeHeight = totalNodeHeight + _h;
                        // cell_idx = cell_idx + 1
                        previousChildNode = firstNodeInCell;
                    }
                });

                if (tab1.rows.length === 0) {
                    tab1.insertRow(0);
                    tab1.rows[0].outerHTML = newRow.outerHTML;
                } else {
                    var lastTableRow = tab1.rows[tab1.rows.length - 1];
                    if (lastTableRow.getAttribute("data-row-id") != newRow.getAttribute("data-row-id")) {
                        tab1.querySelector("tbody").appendChild(newRow);
                    }
                }

                // var isLastTDEmpty = true

                // var firstRowCells =  tr.childNodes

                // firstRowCells.forEach(td=>{
                //     if(td.childNodes.length>=1){
                //         isLastTDEmpty = false;
                //     }
                // })

                if (this.checkEmptyTableRow(tr)) {
                    tr.parentNode.removeChild(tr);
                }
                break;
            }

            tab2LastRowHeight = this.getNodeHeight(tab2rows[0]);
        }
        //Check after removing from second table , if its empty, remove it !
        if (tab2.rows.length === 0) tab2.parentNode.removeChild(tab2);
    }

    /*
        Merge li items of second list into first li until page size is reached.
    */
    handleLinkedList(ul1, ul2) {
        var page1 = this.getEnclosingPage(ul1);

        ul2.childNodes.forEach((li) => {
            var liHeight = li.offsetHeight;
            if (!this.isContentOverflowingOnNodeAddition(page1, liHeight)) {
                ul1.appendChild(li);
            }
        });

        //Check after removing from second list , if its empty, remove it !
        if (ul2.childNodes.length === 0) ul2.parentNode.removeChild(ul2);
    }

    /**Shifts content to the previous page if there is space.
     * @param  currentPageId  - Id of the current page
     */
    shiftContentToPreviousPages(currentPageId) {
        var regex = /\d+/g;
        var id = parseInt(currentPageId.match(regex)[0]);
        var totalPages = this.getPageCount();

        if (id > totalPages) {
            return;
        }

        let nextPageId = `page--${id + 1}`;

        let nextPageNodes = [...this.getContentsOfPage(nextPageId)];
        var currentPage = this.getPageById(currentPageId);
        var currentPageNodes = [...this.getContentsOfPage(currentPageId)];

        let headerAndFooter = this.getHeaderAndFooterHeight(currentPageId);
        // let totalheight = [...this.getContentsOfPage(currentPageId)].reduce((a,b)=> a+b.offsetHeight , headerAndFooter.header+headerAndFooter.footer)
        let totalheight = this.getPageHeight(currentPageId);

        var lastNodeInCurrentPage = currentPageNodes[currentPageNodes.length - 1];
        var firstNodeofNextPage = nextPageNodes[0];

        // If the first node & last node contains some child elements...
        if (lastNodeInCurrentPage && firstNodeofNextPage && lastNodeInCurrentPage.childNodes.length > 0 && firstNodeofNextPage.childNodes.length > 0) {
            //Check if nodes are linked & of allowed linkable types
            var linked = this.areNodesLinkedContentType(lastNodeInCurrentPage, firstNodeofNextPage);

            if (firstNodeofNextPage.childNodes[0].nodeName === "TABLE") {
                var tab2 = firstNodeofNextPage.childNodes[0];
                this.handleLinkedTable(nextPageId, tab2);
            } else if (linked && linked.type === "list") {
                var ul1 = lastNodeInCurrentPage.childNodes[0];
                var ul2 = firstNodeofNextPage.childNodes[0];
                this.handleLinkedList(ul1, ul2);
            } else {
                for (var i = 0; i < nextPageNodes.length; i++) {
                    totalheight = totalheight + nextPageNodes[i].offsetHeight;
                    if (totalheight < this.pageBreakHeight) {
                        this.addToPage(currentPage, nextPageNodes[i]);
                        nextPageNodes[i].className = "";
                        nextPageNodes[i].classList.add(currentPageId);
                    }
                }
            }
        }
        // recursilvely shift content up..
        this.shiftContentToPreviousPages(nextPageId);

        // Remove empty pages from the bottom that might have produced due to reshifting contents.
        this.clearEmptyPages();
    }

    addPageToEditor(page) {
        var editor = document.getElementById("editor");
        editor.appendChild(page);
    }

    /**Shifts content to the next if there is current page overflows.
     * @param  currentPageId  - Id of the current page
     */
    shiftContentToNextPages(currentPageId) {
        /* Check if there is space to shift content, if so continue shifting
        / if not, then create a new page & add content to that
        /
        / 1. if there is no extra page, then add a page, start appending content,
        / 2. if there is content still left , go on adding page & adding content to it
        / 3. append all pages to the document
        */

        var regex = /\d+/g;
        var id = parseInt(currentPageId.match(regex)[0]);

        //remove last node from current page
        var nodesInPage = [...document.querySelectorAll(`.${currentPageId}`)];
        var lastNode = nodesInPage[nodesInPage.length - 1];

        var overflowingNodes = [];
        let headerAndFooter = this.getHeaderAndFooterHeight(currentPageId);
        var nodesInPage = [...this.getContentsOfPage(currentPageId)];
        var pageHeight = this.getPageHeight(currentPageId);

        while (pageHeight > this.pageBreakHeight) {
            var nn = nodesInPage.pop();
            overflowingNodes.push(nn);
            pageHeight = pageHeight - this.getNodeHeight(nn);
        }

        let pages = [];
        // let nodesTotalHeight = lastNode.offsetHeight + 60 + 60;
        let nodesTotalHeight = overflowingNodes.reduce((a, b) => a + this.getNodeHeight(b), 0); //+ 60 + 60;

        // Insert overflowing nodes into pages until all nodes are exhausted.
        var nodeGroups = [];
        var nodeGroup = [];
        nodeGroups.push(nodeGroup);
        var nodeGroupHeight = 120;
        while (overflowingNodes.length >= 1) {
            var n = overflowingNodes.pop();
            nodeGroupHeight = nodeGroupHeight + this.getNodeHeight(n);
            if (nodeGroupHeight <= this.pageBreakHeight) {
                nodeGroup.push(n);
            } else {
                nodeGroups.push(nodeGroup);
                nodeGroup = [];
                nodeGroupHeight = 120;
            }
        }

        var pid = id;
        nodeGroups.forEach((group) => {
            pid = pid + 1;
            //Create pages in memory & do some basic properties, add content to them
            let page = this.createNewPage(`page--${pid}`, true);
            page.id = `page--${pid}`;

            // this.prependInPage(page, [lastNode])
            this.prependInPage(page, group);

            pages.push(page);
        });

        //for every subsequent page
        var totalPages = this.getPageCount();

        for (var i = 1; i <= totalPages; i++) {
            if (i > pid) {
                // get all nodes from page
                var nodes = [...document.querySelectorAll(`.page--${i}`)];

                //start adding them to new pages
                nodes.forEach((node) => {
                    nodesTotalHeight = nodesTotalHeight + node.offsetHeight;

                    if (nodesTotalHeight < this.pageBreakHeight) {
                        // Remember we are only shifting nodes in content-area, not headers / footers.
                        if (!node.classList.contains("header--item")) this.addToPage(page, node);
                    } else {
                        page = this.createNewPage(`page--${i + 1}`, true);

                        // page,header.quew(sjnfwef)
                        page.id = `page--${i + 1}`;
                        this.applyHeaderClass(page.querySelector(".editor-header").children, page.id);

                        let headerAndFooter = this.getHeaderAndFooterHeight(page.id);

                        // Reset nodes height to the height of current node
                        if (!node.classList.contains("header--item")) this.addToPage(page, node);
                        nodesTotalHeight = node.offsetHeight + headerAndFooter.header + headerAndFooter.footer;
                        pages.push(page);
                    }
                });

                //remove the page as we are goin to insert it in a new page anyways.
                // remove only after height for all nodes has been calculated
                this.removePage(`page--${i}`);
            }
        }

        var editor = document.getElementById("editor");

        //Attach pages to the dom
        pages.forEach((page) => {
            editor.appendChild(page);
        });

        this.handleTableSplt(currentPageId);
    }

    init() {
        if (this.options.ui) {
            var page = this.createNewPage();
            page.id = "page--1";
            this.applyHeaderClass(page.querySelector(".editor-header").children, page.id);

            this.container.appendChild(page);
            // this.focusCursor(this.page)
            // this.handleInput();
        }
    }

    /** This may replace focusCursor late on, with some modification!! */
    focusCursorToEnd(target) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(target);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        // target.focus();
        // range.detach(); // optimization
    }
    /**
     *
     * @param {domNode} page - Dom Node on which to focus the cursor
     */
    focusCursor(page, start) {
        var selection = window.getSelection ? window.getSelection() : document.selection.createRange().text;
        var range = document.createRange();
        if (start) {
            range.setStart(page, start);
        } else {
            range.setStart(page, 0);
        }
        range.collapse(true);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    /**
     *
     * @param {string} pageId - Return if the contents of the
     * page overflows the pagebreak height
     */
    checkNeedsPageBreak(pageId) {
        let domId = `.${pageId}`;
        let headerAndFooter = this.getHeaderAndFooterHeight(pageId);
        let contentHeight = this.getPageHeight(pageId);
        return contentHeight >= this.pageBreakHeight;
    }

    // isFirstNodeInPage(pageId, node){
    //     let children = node.parentNode.childNodes
    //     return (children.length === 1
    //         && children[0] === node)
    // }

    isFirstNodeInPage(pageId, node) {
        let contents = [...this.getContentsOfPage(pageId)];

        if (node.nodeType === 3 || node.nodeType === 1) {
            return node.parentNode === contents[0];
        } else {
            return node === contents[0];
        }
    }

    isEmptyEditableNode(node) {
        let activeNode = node;
        if (node.nodeType === 3) {
            activeNode = node.parentNode;
        }
        if (activeNode.childNodes.length === 0) return true;
        else if (activeNode.childNodes.length === 1 && activeNode.childNodes[0].tagName === "BR") return true;
        return false;
    }

    getActivePageId(isSelectedElement) {
        if (this.activeEditorNode) {
            //If there is activeNode but closest ('.neo-page) return null,
            //It menas it has been removed from the dom tree, ex in case of pressing backsapace,
            // So get the active node again
            return this.activeEditorNode.closest(".neo-page")?.id ?? this.getActiveNode().closest(".neo-page")?.id;
        }

        var activeElement = isSelectedElement ? isSelectedElement : this.prevCursorSelection;

        if (activeElement.commonAncestorContainer.classList && [...activeElement.commonAncestorContainer.classList].includes("neo-page")) {
            activeElement = window.getSelection().anchorNode;
        }

        if (!this.isDOM(activeElement)) {
            activeElement = window.getSelection().anchorNode.parentNode;
        }

        if (!activeElement) {
            activeElement = window.getSelection().anchorNode;
        }

        var pageId = activeElement.closest(".neo-page").id;
        var id = this.getIdFromPage(pageId);
        return `page--${id}`;
    }

    /**
     * Returns the ID of next page
     */
    getNextPageId() {
        // var activeElement = window.getSelection().anchorNode

        // if(!this.isDOM(activeElement)){
        //     activeElement = window.getSelection().anchorNode.parentNode
        // }

        // var pageId = activeElement.closest('.neo-page').id
        var currentPageId = this.getActivePageId();
        var id = this.getIdFromPage(currentPageId);
        return `page--${id + 1}`;
    }

    /**
     * Return the ID of previous page
     */
    getPreviousPageId() {
        var activeElement = window.getSelection().anchorNode;

        if (!this.isDOM(activeElement)) {
            activeElement = window.getSelection().anchorNode.parentNode;
        }

        var pageId = activeElement.closest(".neo-page").id;
        var id = this.getIdFromPage(pageId);
        return `page--${id - 1}`;
    }

    /**Return page dom node, given its pageId
     *
     * @param {string} pageId
     */
    getIdFromPage(pageId) {
        var regex = /\d+/g;
        var id = parseInt(pageId.match(regex)[0]);
        return id;
    }

    getActiveNode() {
        var anchorNode = window.getSelection().anchorNode;
        // if text
        if (anchorNode.nodeType === 3) {
            //if the text is inside some span
            if (anchorNode.parentNode.tagName === "SPAN") {
                return anchorNode.parentNode.parentNode;
            }
            return anchorNode.parentNode;
        }

        // if node is not a text
        return anchorNode;
    }

    getContentsOfPage(pageId) {
        var page = this.getPageById(pageId);
        try {
            var contentArea = page.querySelector(`#${page.id}>.content--area`);
            return contentArea.childNodes;
        } catch (err) {
            return [];
        }
    }
    isDOM(obj) {
        return obj && obj.tagName && obj.removeAttribute;
    }

    /**Return height of a dom node
     *
     * @param {node} node
     */

    getNodeBoundingClientRect(node) {
        if (node.nodeType === 3) {
            if (document.createRange) {
                var range = document.createRange();
                range.selectNodeContents(node);
                if (range.getBoundingClientRect) {
                    var rect = range.getBoundingClientRect();
                    return rect;
                }
            }
        } else {
            return node.getBoundingClientRect();
        }
    }

    getNodeHeight(node) {
        if (node === undefined || node === null) return 0;
        if (node.nodeName === "BR") return 0;
        if (node.nodeType === 3 && node.parentNode.nodeName === "TD") {
            let rect = this.getNodeBoundingClientRect(node);
            if (rect) {
                var height = rect.bottom - rect.top;
                return height;
            }
        }
        if (node.nodeType === 3) return node.parentNode.offsetHeight;
        else return node.offsetHeight;
    }

    isPageEmpty(pageId) {
        //nodes...
        let nodesInPage = this.getContentsOfPage(pageId);
        return [...nodesInPage].every((el) => el.childNodes.length === 0 || (el.childNodes.length == 1 && el.childNodes[0].tagName === "BR"));
    }

    getPageActiveHeight(pageId) {
        let headerAndFooter = this.getHeaderAndFooterHeight(pageId);
        return [...this.getContentsOfPage(pageId)].reduce((a, b) => a + b.offsetHeight, headerAndFooter.header + headerAndFooter.footer);
    }
    insertAfterNodeInPage(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    isTextNode(node) {
        //Given a row node object (div.page--*), check if contains spaned texts or just text
        var innerNodes = [...node.querySelectorAll("*")];

        return innerNodes.every((e) => {
            if (e.tagName === "SPAN") {
                if (e.firstElementChild?.nodeType === 3 || e.firstChild.nodeType === 3) return true;
            }
            if (e.tagName === "BR") return true;
            if (e.nodeType === 3) return true;

            //some node othe than a direct span & text node is found
            return false;
        });
    }

    attachRemovableOptionToPage(page) {
        var ico = require(`@/assets/icons/cancel.svg`);
        var div = document.createElement("div");
        div.classList.add("page--remover");
        var img = document.createElement("img");
        img.src = ico;
        div.appendChild(img);
        div.style.position = "absolute";
        div.style.width = "20px";
        div.style.right = "0px";
        div.style.padding = "4px";
        div.style.cursor = "pointer";
        div.style.background = "#f77373";
        div.style.borderBottomLeftRadius = "4px";
        div.addEventListener("click", (e) => {
            var pageId = div.closest(".neo-page").id;
            var regex = /\d+/g;
            var id = parseInt(pageId.match(regex)[0]);
            //remove the page,
            page.parentNode.removeChild(page);

            //for every page get their ids,
            var pages = [...document.querySelectorAll(".neo-page")];
            pages.forEach((page) => {
                var pid = page.id;
                var regex = /\d+/g;
                var pid = parseInt(pid.match(regex)[0]);
                if (pid > id) {
                    //for all contents of this page, change the page id to -1
                    var pageContents = page.querySelectorAll(`#${page.id}`);
                    var newId = `page--${pid - 1}`;
                    page.id = newId;

                    pageContents.forEach((item) => {
                        item.id = newId;
                    });
                }
            });
        });
        page.appendChild(div);
    }

    getTextFromNode(node) {
        //Given a row node object (div.page--*), check if contains spaned texts or just text
        var innerNodes = [...node.querySelectorAll("*")];

        var text = "";

        if (innerNodes.length == 0) {
            return node.innerText;
        }

        innerNodes.forEach((e) => {
            if (e.tagName === "SPAN") {
                if (e.firstElementChild?.nodeType === 3 || e.firstChild.nodeType === 3) text = text + e.firstChild.wholeText;
            }
            // if(e.tagName === "BR") break;
            if (e.nodeType === 3) text = text + e.firstChild.wholeText;

            //some node othe than a direct span & text node is found
        });

        return text;
    }

    mergeTextNodes(node1, node2) {
        //remove
        // var br = [...node1.querySelectorAll('br')]

        // if(br && br[br.length-1].tagName === "BR"){
        //     br[br.length -1].parentNode.removeChild(br[br.length-1])
        // }

        [...node2.childNodes].forEach((e) => node1.appendChild(e));
        return node1;
    }

    mergeTables(tab1, tab2) {}

    handleBackspaceAfterKeyUp(e) {
        // This function is called after backspace is pressed & key is released,
        // i.e when the node is removed from the Dom or some equivalent action has been taken.
        var currentPageId = this.getActivePageId();
        this.shiftContentToPreviousPages(currentPageId);
        // var actvieNode = this.getActiveNode();
        // var nodesInPage = [...this.getContentsOfPage(currentPage)];

        // var nextPageId = this.getNextPageId()
        // var nodesInNextPage = [...this.getContentsOfPage(nextPageId)].slice().reverse()

        // let headerAndFooter = this.getHeaderAndFooterHeight(currentPage);
        // var currentPageHeight = headerAndFooter.header + headerFooterHeight.footer + nodesInPage.reduce((a,b) => a+ this.getNodeHeight(b), 0)

        // //See can we do the shifting???
        // while(currentPageHeight <= this.pageBreakHeight){
        //     var node = nodesInNextPage.pop()
        //     this.addToPage(page, node)
        //     currentPageHeight = currentPageHeight + this.getNodeHeight(node)
        // }
        // let pages = this.getPageCount()

        // var regex = /\d+/g;
        // var id = parseInt(currentPage.match(regex)[0]);

        // // Start removing Empty pages from the bottom

        // while( pages > 1 ) {
        //     var pageId = `page--${pages}`
        //     if(this.isPageEmpty(pageId)){
        //        this.removePage(pageId)
        //    }
        //    pages = pages -1

        // }
    }

    handleBackspace(e) {
        /* handle when the curosr is at the top left of some page in between othe rpages*/
        /* When the node above is a text node, & the current node is a text node, merge them */

        // if(!this.getRange().collapsed) return;

        var range = this.getRange();
        // This handles the case when neo-page is selected for some reason ^ backspace is hit

        if (this.selectedElement) {
            this.selectedElement.remove();
            this.selectedElement = null;
            return;
        }

        if (range.commonAncestorContainer.className === "neo-page") {
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            return;
        }
        var actvieNode = this.getActiveNode();
        var currentPage = this.getActivePageId();

        var nodesInPage = [...this.getContentsOfPage(currentPage)];
        let headerAndFooter = this.getHeaderAndFooterHeight(currentPage);

        if (currentPage === `page--1` && document.querySelector(`#${currentPage}`).textContent === "" && this.getContentsOfPage(currentPage).length === 1) {
            // This case happend when we add some lines to page , convert them to list type then remove all list one by one .
            //  This will lead to an editor state in which the conten will be like :
            // <div conten--area>
            //         <div page--1>
            //             <div></div>
            //         </div>
            // </div>

            // remove trailing bullets from page
            [...document.querySelectorAll(`#page--1  ul`)].forEach((ul) => ul.parentNode.removeChild(ul));
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            return;
        }

        var totalHeightOfCurrentPage = nodesInPage.reduce((a, b) => a + b.offsetHeight, headerAndFooter.header + headerAndFooter.footer);

        // if last node in previous page is also a text node
        var previousPageId = this.getPreviousPageId();
        var nodesInPreviousPages = [...this.getContentsOfPage(previousPageId)];

        var isFirstNode = this.isFirstNodeInPage(currentPage, actvieNode);
        var currentNodeEmpty = this.isEmptyEditableNode(actvieNode);

        // When the cursor is in the first page & there is no content  , to prevent header
        //from being deleter, stop browser from handling the backend event

        if (isFirstNode && currentNodeEmpty) {
            // if the cursor is in top left of page other than first page, do
            //the reshifting & focus curosr back to the previous page

            var id = this.getIdFromPage(currentPage);

            if (id > 1) {
                // var nodes = document.querySelectorAll(`.${previousPageId}`)
                var lastNodeInPage = nodesInPreviousPages[nodesInPreviousPages.length - 1];
                this.focusCursor(lastNodeInPage);
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
            } else {
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
            }
            return;
        }

        var lastNodeofPreviousPage = nodesInPreviousPages[nodesInPreviousPages.length - 1];

        // if(lastNodeofPreviousPage.querySelector('tbody') && nodesInPage[nodesInPage.length-1].querySelector('tbody') ){

        //     //if both are linked tables
        //     var tab1Id = lastNodeofPreviousPage.querySelector('tbody').getAttribute('data-table-id')
        //     var tab2Id = nodesInPage[nodesInPage.length-1].querySelector('tbody').getAttribute('data-table-id')
        //     if (tab1Id === tab2Id){
        //         //start iterating for eah row of t2

        //     }
        // }

        // var nextPageId = this.getNextPageId()
        // var nextPageContents

        if (this.isTextNode(actvieNode) && nodesInPage[0] === actvieNode && window.getSelection().anchorOffset === 0) {
            /** If the cursor is on a text node, & top left of page , before text ex .  |xxx  */

            //Handle events manually
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            // 1. If the last node in previous page is also text

            // if(lastNodeofPreviousPage.childNodes[0].nodeType === 3 ){
            if (this.isTextNode(lastNodeofPreviousPage)) {
                lastNodeofPreviousPage = this.mergeTextNodes(lastNodeofPreviousPage, actvieNode);
                actvieNode.parentNode.removeChild(actvieNode);

                totalHeightOfCurrentPage = nodesInPage.reduce((a, b) => a + b.offsetHeight, headerAndFooter.header + headerAndFooter.footer);

                this.focusCursor(lastNodeofPreviousPage);

                headerAndFooter = this.getHeaderAndFooterHeight(previousPageId);

                //Recalcualte height of previous page
                totalHeightOfPreviousPage = nodesInPreviousPages.reduce((a, b) => a + b.offsetHeight, headerAndFooter.header + headerAndFooter.footer);

                if (totalHeightOfPreviousPage > this.pageBreakHeight) {
                    this.shiftContentToNextPages(previousPageId);
                }
            }

            // 2. if the last node in previous page are blank lines
            else {
                let removableNodesHeight = 0;

                //can we make space by deleteing last empty nodes?
                for (ci = nodesInPreviousPages.length - 1; ci >= 0; ci--) {
                    if (this.isEmpty(nodesInPreviousPages[ci])) {
                        if (nodesInPreviousPages[ci].nodeType === 3) removableNodesHeight = removableNodesHeight + nodesInPreviousPages[ci].parentNode.offsetHeight;
                        else removableNodesHeight = removableNodesHeight + nodesInPreviousPages[ci].offsetHeight;
                    } else {
                        break;
                    }
                }

                // Check if we insert the current node by removeing empty rows from previous page

                if (removableNodesHeight >= this.getNodeHeight(actvieNode)) {
                    //can we make space by deleteing last empty nodes?
                    for (i = nodesInPreviousPages.length - 1; i >= 0; i--) {
                        if (this.isEmpty(nodesInPreviousPages[i])) {
                            nodesInPreviousPages[i].parentNode.removeChild(nodesInPreviousPages[i]);
                        } else {
                            break;
                        }
                    }
                    this.shiftContentToPreviousPages(previousPageId);
                }

                // Check if there is simply , extra space available
                if (currentPage != "page--1") {
                    headerAndFooter = this.getHeaderAndFooterHeight(previousPageId);
                    var totalHeightOfPreviousPage = nodesInPreviousPages.reduce((a, b) => a + b.offsetHeight, headerAndFooter.header + headerAndFooter.footer);
                    if (totalHeightOfPreviousPage + this.getActiveNode(actvieNode) <= this.pageBreakHeight) {
                        this.shiftContentToPreviousPages(previousPageId);
                    }
                }
            }
        } else {
            if (nodesInPage[0] === actvieNode && actvieNode.firstChild.nodeType != 3) {
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;

                let removableNodesHeight = 0;
                // let insertAfterNode = null;
                let removeNodesUntilIndex = null;

                //can we make space by deleteing last empty nodes?
                for (var ci = nodesInPreviousPages.length - 1; ci >= 0; ci--) {
                    if (this.isEmpty(nodesInPreviousPages[ci])) {
                        removableNodesHeight = removableNodesHeight + nodesInPreviousPages[ci].offsetHeight;
                        if (removableNodesHeight >= this.getNodeHeight(actvieNode)) {
                            removeNodesUntilIndex = ci;
                            break;
                        }
                    } else {
                        break;
                    }
                }

                if (removeNodesUntilIndex != null) {
                    //remove nodes until removable, to make space for shifting, below to work.
                    for (ci = nodesInPreviousPages.length - 1; ci >= removeNodesUntilIndex; ci--) {
                        nodesInPreviousPages[ci].parentNode.removeChild(nodesInPreviousPages[ci]);
                    }
                    this.shiftContentToPreviousPages(previousPageId);
                }
            }
        }

        if (nodesInPage[0] === actvieNode && actvieNode.innerHTML === "<br>" && actvieNode.childNodes.length === 1) {
            // do nothing jst focus the cursor to the  last node of previous page
            this.focusCursor(lastNodeofPreviousPage);
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
        }

        // handle if cursor is not at top of page and node is table
        if (!isFirstNode && actvieNode.classList.contains("table--container")) {
            this.focusCursor(actvieNode.previousSibling);
            e.preventDefault();
            e.stopPropagation();
            e.cancelBubble = true;
            return;
        }

        // // If current page is not the only page, or the last one & there is some spcae left in the page , then do the reshifting
        // if(totalHeightOfCurrentPage < this.pageBreakHeight && currentPage != `page--${this.getPageCount()}`){
        //     this.shiftContentToPreviousPages(currentPage)
        // }

        /*When backspace is clicked check if the node in below page be accomodated in the current page,
         / if so , shift up the node , & perform reshifting nodes up recursively in below pages */
        /*check if bewlow pages need to be removed,
            check if there is some space in current page & no content in next page, remove the next page from document
         */
        //  let pages = this.getPageCount()

        //  var regex = /\d+/g;
        //  var id = parseInt(currentPage.match(regex)[0]);

        //  // Start removing Empty pages from the bottom

        //  while( pages > 1 ) {
        //      var pageId = `page--${pages}`
        //      if(this.isPageEmpty(pageId)){
        //         this.removePage(pageId)
        //     }
        //     pages = pages -1
        // }

        //  let currentPageHeight = this.getPageActiveHeight(currentPage)
        // //  Cant remove first page, even if its empty
        //      for(var i=2 ; i<=pages; i++ ) {
        //          var pageId = `page--${i}`
        //          if(this.isPageEmpty(pageId) && currentPageHeight < this.pageBreakHeight){
        //             //  this.removePage(pageId)
        //          }
        //      }
    }

    getIndex(node) {
        var n = 0;
        while ((node = node.previousSibling)) n++;

        return n;
    }

    splitParaInLines(text) {
        return text.match(/.{1,80}/g);
    }

    handlePaste(container) {
        let that = this;
        container = container || this.container;
        //         var pageId = this.getActivePageId()
        container.addEventListener("paste", (event) => {
            let file = event.clipboardData.files;
            let pageId = that.getActivePageId();
            if (file.length > 0) {
                event.preventDefault();
                var items = (event.clipboardData || event.originalEvent.clipboardData).items;
                for (var index in items) {
                    var item = items[index];
                    if (item.kind === "file") {
                        var blob = item.getAsFile();
                        var reader = new FileReader();
                        reader.onload = (event) => {
                            let range = this.prevCursorSelection;
                            let imgWrap = document.createElement("div");
                            const img = new Image();
                            img.src = event.target.result;
                            img.setAttribute("draggable", "false");
                            imgWrap.style.height = "auto";
                            imgWrap.style.width = "200px";
                            imgWrap.style.marginRight = "15px";
                            imgWrap.classList.add("resizable");
                            imgWrap.setAttribute("contenteditable", false);
                            imgWrap.appendChild(img);
                            range.insertNode(imgWrap);

                            var emptyRow = this.createNewRow();
                            emptyRow.classList.add(pageId);

                            //Add empty row after image
                            this.insertAfterNodeInPage(emptyRow, imgWrap.parentNode);
                            this.focusCursor(emptyRow);
                        }; // data url!
                        reader.readAsDataURL(blob);
                    }
                }
            } else {
                let lastNode = null;
                event.preventDefault();
                let paste = (event.clipboardData || window.clipboardData).getData("text/plain");
                // let pastedItems = paste.split(/\n+/g);
                let pastedItems = paste;
                const selection = window.getSelection();
                if (!selection.rangeCount) return false;
                selection.deleteFromDocument();
                let nodes = [];
                if (selection.baseNode.nodeName == "TD" || selection.baseNode.parentNode.nodeName === "TD") {
                    let text = "";
                    var focusedNode = null;
                    // Find index where to start inserting Nodes, as paste action `P` could have been initiated while in between text || end of all ltext || starting of all texts
                    // When `P` occurs in empty line,

                    if (window.getSelection().anchorNode.nodeType == 1) {
                        if (window.getSelection().anchorNode.nodeName === "BR") {
                            focusedNode = window.getSelection().anchorNode;
                        } else {
                            var focusedOffset = window.getSelection().focusOffset;
                            focusedNode = window.getSelection().anchorNode.childNodes[focusedOffset];
                        }
                    } else {
                        focusedNode = window.getSelection().anchorNode;
                    }

                    pastedItems.split(/\n+/g).forEach(function (item) {
                        text = text + "<br>" + item;
                        var textNode = document.createTextNode(item);

                        // Refactor this part later , on..!!
                        if (!focusedNode) {
                            // If there is no content in the table
                            that.getActiveNode().appendChild(textNode);
                            focusedNode = textNode;
                            var breakNode = document.createElement("br");
                            focusedNode.parentNode.insertBefore(breakNode, focusedNode.nextSibling);
                            focusedNode = breakNode;
                        } else {
                            focusedNode.parentNode.insertBefore(textNode, focusedNode.nextSibling);
                            focusedNode = textNode;
                            var breakNode = document.createElement("br");
                            focusedNode.parentNode.insertBefore(breakNode, focusedNode.nextSibling);
                            focusedNode = breakNode;
                        }
                        // nodes.push(textNode)
                    });
                    // if(that.isTextNode(activeNode)){
                    //     //join this node + first node of clipboard
                    // }

                    // referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);

                    // selection.baseNode.innerHTML = selection.baseNode.innerHTML + "<br>" + text;
                    // var childNodes = [...selection.baseNode.childNodes]
                    //  lastNode  = childNodes[childNodes.length - 1]

                    that.focusCursor(focusedNode, focusedNode.length ?? 0);
                } else {
                    var after = selection.baseNode.nodeName == "#text" ? selection.baseNode.parentNode : selection.anchorNode;
                    var lines = that.splitParaInLines(pastedItems);
                    let wrapper = null;
                    lines.forEach(function (item, index) {
                        wrapper = document.createElement("div");
                        wrapper.className = pageId;
                        wrapper.innerHTML = item;
                        that.insertAfterNodeInPage(wrapper, after);
                        after = wrapper;
                        try {
                            that.focusCursor(wrapper.firstChild, wrapper.firstChild?.textContent.length ?? 0);
                        } catch (err) {}
                        // if(that.checkNeedsPageBreak(pageId)){
                        //     that.shiftContentToNextPages(pageId);
                        // }
                    });

                    //                      if(that.checkNeedsPageBreak(pageId)){
                    //
                    //                             that.shiftContentToNextPages(pageId);
                    //                         }
                    //                     that.focusCursor(wrapper.firstChild, wrapper.firstChild?.textContent.length ?? 0)
                }
                this.handleTableSplt(pageId);
                this.shiftContentToNextPages(pageId);
                //              this.handleBulletListOnEnter();
                // selection.getRangeAt(0).insertNode(wrapper);
            }
        });
    }

    createNewLineElement(pageID) {
        var newRow = this.createNewRow();
        newRow.classList.add(pageID);
        newRow.innerText = "";
        var previousPage = this.getPageById(pageID);
        this.addToPage(previousPage, newRow);
        this.focusCursor(newRow);
    }

    getLastNodeOfPage(pageId) {
        var currentPageNodes = [...this.getContentsOfPage(pageId)];
        var lastNode = currentPageNodes[currentPageNodes.length - 1];
        return lastNode;
    }

    handleBulletListOnEnter() {
        //if overlowing page?

        var pageId = this.getActivePageId();
        var pageHeight = this.getHeaderAndFooterHeight(pageId);
        var height = pageHeight.header + pageHeight.footer;
        var page = this.getPageById(pageId);

        var regex = /\d+/g;
        var id = parseInt(pageId.match(regex)[0]);

        var nodesHeight = [...this.getContentsOfPage(pageId)].reduce((a, b) => a + b.offsetHeight, 0);
        var contentHeight = height + nodesHeight;
        var lastNode = this.getLastNodeOfPage(pageId);

        var linkedUl = document.createElement("ul");
        var uid = null;
        if (lastNode.childNodes.length > 0 && lastNode.childNodes[0].nodeName === "UL") {
            uid = lastNode.childNodes[0].getAttribute("data-uid");
            linkedUl.setAttribute("data-uid", uid);

            //Last Node is list , do the magic here!.
            var liNodes = [...lastNode.childNodes[0].childNodes];

            while (contentHeight > this.pageBreakHeight) {
                var node = liNodes.pop();
                var nodeHeight = this.getNodeHeight(node);
                if (nodeHeight === 0) nodeHeight = this.getNodeHeight(node.closest("li"));
                linkedUl.appendChild(node);
                contentHeight = contentHeight - nodeHeight;
            }

            var emptyRow = this.createNewRow();
            emptyRow.classList.add(pageId);
            emptyRow.appendChild(linkedUl);

            this.addToPage(page, emptyRow);
            this.shiftContentToNextPages(pageId);

            // var pageCount = this.getPageCount()
            // var nextPageId = this.getNextPageId()
            // var nextPageContents = this.getContentsOfPage(nextPageId)

            this.focusCursor(emptyRow);

            //Fix linked ULs constraints

            var nextPageId = this.getNextPageId();
            var nextPage = this.getPageById(nextPageId);
            var linkedUls = [...nextPage.querySelectorAll(`[data-uid="${uid}"`)];
            if (linkedUls.length > 1) {
                [...linkedUls[1].querySelectorAll("li")].forEach((li) => {
                    linkedUls[0].appendChild(li);
                });
                linkedUls[1].closest("div").parentNode.removeChild(linkedUls[1].closest("div"));
            }
            // var pageCount = this.getPageCount()
            // //If there is first page
            // //otherwise
            // if(id === pageCount){
            //     var page = this.createNewPage()
            // }
        }
    }

    /**
     * Handles tables on pressing enter key, 
     * 1. Splitting.
     * 2. Shifting to next pages.
     * 3. Creating new page
      Check if height overflown,
        - If there is no page below, create a new page and create a linked table
        - If there is existing page below
             - Check if the first Item of next page is table
                 - If the first item is table & linked to previous table
                    - Start a merge
                 - If all cells of last rows are empty
                    - Remove that row & create a new row in next table
                 - If some cell has some text content
                    - Check if any cell has a nested table in it.
        -   if nested table move entire row.
              - If no nested table
                    -  Find the cell with maximum lines and sart  removing from it.
        - Copy attributes of previous tables (border colors, backgrounds, to the new linked table
        - If table cells had icons in them setup icons click listeners again after moving them to new  rows.

     */
    handleTableSplt(currentPageId) {
        // var currentPageId = this.getActivePageId()

        //check total height of page, can we expand more content here??
        let currentPageHeight = this.getPageHeight(currentPageId);

        var currentPageNodes = [...this.getContentsOfPage(currentPageId)];
        var lastNode = currentPageNodes[currentPageNodes.length - 1];
        var currentPage = this.getActivePageId();

        if (currentPageHeight >= this.pageBreakHeight) {
            if (lastNode.childNodes.length > 0 && lastNode.childNodes[0].nodeName === "TABLE") {
                //Recursilvely find nodes in table cells removing which can satisy the height constraint of page
                var table = lastNode.childNodes[0];

                // var noedsInLastCell = [...table.rows[table.rows.length -1].childNodes]
                var currenTableId = table.getAttribute("data-table-id");

                var removaleNodeHeight = 0;

                // To find the nodes which has to be removed, find the cell which has most no of ndoes.
                // var reducibleCell = noedsInLastCell[0]

                var heightDiff = currentPageHeight - this.pageBreakHeight;

                var rows = [...table.rows];

                var tab = this.getLinkedTable(currentPageId, currenTableId);
                tab.setAttribute("type", "linked");

                var insertBefore = null;

                while (rows.length > 0 || heightDiff === 0) {
                    var tr = rows.pop();

                    //For every row find if row needs to be split or moved as a whole
                    //                     if( tr.offsetHeight <= heightDiff && tr.getAttribute('data-row-id') !== tab.rows[0].getAttribute('data-row-id')){
                    if (tr.offsetHeight <= heightDiff) {
                        if (tab.rows.length > 0 && tr.getAttribute("data-row-id") === [...tab.rows][0].getAttribute("data-row-id")) {
                            // merge splited rows
                            let cells = [...tr.childNodes];
                            let tabFirstRow = [...tab.rows][0];
                            let tabFirstRowCells = [...tabFirstRow.childNodes];
                            cells.forEach((cell, cell_idx) => {
                                let childNodes = [...cell.childNodes];
                                let prependNode = null;
                                childNodes.forEach((childNode) => {
                                    if (tabFirstRowCells[cell_idx].childNodes.length === 0) {
                                        tabFirstRowCells[cell_idx].appendChild(childNode);
                                        prependNode = childNodes;
                                    } else {
                                        if (prependNode === null) prependNode = [...tabFirstRowCells[cell_idx].childNodes][0];

                                        prependNode.parentNode.insertBefore(childNode, prependNode);
                                    }
                                });
                            });
                        } else {
                            if (insertBefore === null) {
                                tab.querySelector("tbody").appendChild(tr);
                                insertBefore = tr;
                            } else {
                                insertBefore.parentNode.insertBefore(tr, insertBefore);
                            }
                        }
                        heightDiff = heightDiff - tr.offsetHeight;
                    } else {
                        // split here.
                        var row_uid = null;

                        if (tr.getAttribute("data-row-id")) {
                            row_uid = tr.getAttribute("data-row-id");
                        } else {
                            row_uid = this.guidGenerator();
                        }
                        var newRow = this.getLinkedRow(currentPageId, row_uid, [...tr.childNodes]);
                        //
                        //Mark these rows as linked.
                        tr.setAttribute("data-row-id", row_uid);
                        //                         newRow.setAttribute('rowId',uid)

                        var cells = [...tr.childNodes];
                        cells.forEach((cell, idx) => {
                            var totalNodeHeight = 0;

                            var prependNode = newRow.childNodes[idx].childNodes.length > 0 ? newRow.childNodes[idx].firstChild : null;

                            var childNodes = [...cell.childNodes];
                            // var cellsToSplit = [];
                            var requireSplit = null;
                            let childrenTotalHeight = 0;
                            if (childNodes.length > 0) {
                                let lastChildNode = childNodes[childNodes.length - 1];

                                let lastChildNodeRect = this.getNodeBoundingClientRect(lastChildNode);
                                let firstChildNodeRect = this.getNodeBoundingClientRect(childNodes[0]);

                                childrenTotalHeight = lastChildNodeRect.bottom - firstChildNodeRect.top;
                            }

                            let tableCellPaddind = 4;
                            if (cell.closest("tr").offsetHeight - childrenTotalHeight - tableCellPaddind * 2 <= heightDiff) {
                                //requires split
                                while (childNodes.length > 0 && totalNodeHeight < heightDiff) {
                                    var lastNodeInCell = childNodes.pop();
                                    if (lastNodeInCell === undefined) return;
                                    var _cell = newRow.childNodes[idx];
                                    var _h = this.getNodeHeight(lastNodeInCell);
                                    if (prependNode === null) {
                                        _cell.appendChild(lastNodeInCell);
                                        prependNode = lastNodeInCell;
                                    } else {
                                        prependNode = _cell.firstChild;
                                        prependNode.parentNode.insertBefore(lastNodeInCell, prependNode);
                                    }
                                    totalNodeHeight = totalNodeHeight + _h;
                                }
                            }
                        });

                        if (tab.rows.length === 0) {
                            tab.insertRow(0);
                            tab.rows[0].outerHTML = newRow.outerHTML;
                        } else {
                            var firstTableRow = tab.rows[0];
                            if (firstTableRow.getAttribute("data-row-id") != newRow.getAttribute("data-row-id")) {
                                firstTableRow.parentNode.insertBefore(newRow, firstTableRow);
                            }
                        }
                        var isLastTDEmpty = true;

                        let nodesInLastCell = [...table.rows[table.rows.length - 1].childNodes];

                        nodesInLastCell.forEach((td) => {
                            if (td.textContent) {
                                isLastTDEmpty = false;
                            }
                        });

                        if (isLastTDEmpty) {
                            [...table.rows][table.rows.length - 1].parentNode.removeChild([...table.rows][table.rows.length - 1]);
                        }

                        if (table.rows.length === 0) {
                            var tableDiv = table.parentNode;
                            tableDiv.parentNode.removeChild(tableDiv);
                        }
                        break;
                    }
                }
                return;

                /*
                Do merging
                var tables = document.querySelectorAll('.neo-page table')
                var previousTable = null;
                
                for(let i = 1; i < tables.length ; i++){
                 var prevTable = tables[i-1]
                    var currentTable = tables[i]
                    if( prevTable.getAttribute('data-table-id') === currentTable.getAttribute('data-table-id')
                        && prevTable.getAttribute('type') === 'linked' 
                        && currentTable.getAttribute('type') === 'linked'
                    )
                    {
                    
                        check if last row of first table & first row of last table needs merging??
                    
                    ;[...currentTable.querySelectorAll('tr')].forEach(tr=>{
                        prevTable.querySelector('tbody').appendChild(tr)
                    })
                    
                    currentTable.closest('div').parentNode.removeChild(currentTable.closest('div'))
                    }
                    
                    
                }
                */

                var reducibleCellIndex = 0;

                noedsInLastCell.forEach((cell) => {
                    // var cellHeight = [...cell.childNodes].reduce((a,b)=>a+b.offsetHeight, 0)
                    // var reducibleCellHeight = [...reducibleCell.childNodes].reduce((a,b)=>a+b.offsetHeight, 0)

                    //NOTE: Handle this logic correctly, fails when text size is changed
                    if (cell.childNodes.length >= reducibleCell.childNodes.length) {
                        reducibleCell = cell;
                        reducibleCellIndex = noedsInLastCell.findIndex((c) => c === cell);
                    }
                });

                // var reducibleCellCopy  = reducibleCell.cloneNode(true)
                var reducibleCellNodes = [...reducibleCell.childNodes];
                var removableNodes = [];

                for (var i = reducibleCellNodes.length - 1; i >= 0; i--) {
                    var node = reducibleCellNodes[i];

                    // if(node.nodeName === "BR"){
                    //Removing only br nodes, other nodes, need not be removed as ,
                    //their parents will be changed later on
                    // node.parentNode.removeChild(node)
                    // continue;
                    // }

                    currentPageHeight = currentPageHeight - this.getNodeHeight(node);
                    if (currentPageHeight >= this.pageBreakHeight) {
                        removableNodes.push(node);
                    } else {
                        break;
                    }
                }

                var nodeGroupHeight = [];
                var nodeGroup = [];
                var group = [];
                var groupRunningHeight = 0;

                // Divide nodes into chunks.
                while (removableNodes.length > 0) {
                    var node = removableNodes.pop();
                    groupRunningHeight = groupRunningHeight + this.getNodeHeight(node);
                    if (groupRunningHeight <= this.pageBreakHeight) {
                        group.push(node);
                        continue;
                    }
                    nodeGroup.push(group);
                    groupRunningHeight = this.getNodeHeight(node);
                    group = [node];
                }
                //Now we have removabel nodes calculated we can remove those from original parent,
                removableNodes.forEach((node) => {
                    node.parentNode.removeChild(node);
                });

                var tables = [];

                nodeGroup.forEach((group, idx) => {
                    var newTable = this.createLinkedTable(currenTableId);
                    var tableNodes = nodeGroup[idx];
                    tableNodes.forEach((n) => newTable);
                    tables.push(newTable);
                });

                // Find the cell with max no of nodes(`col_max`),
                // Calculate the no of new rows required to fit in all nodes of `col_max`.
                //

                // for(var i=reducibleCellNodes.length-1; i>=0;i--){
                //     var node = reducibleCellNodes.pop()
                //     if(node.nodeName === "BR"){
                //         node.parentNode.removeChild(node)
                //         continue;
                //     }
                //     // node.parentNode.removeChild(node)
                //     // Remove this node
                //     // node.parentNode.removeChild(node)
                //     //Check new height of Cell
                //     var reducibleCellHeight = reducibleCell.offsetHeight
                //     if(currentPageHeight - reducibleCellHeight < this.pageBreakHeight){
                //         removableNodes.push(node);
                //         break;

                //     }
                // }

                var totalPages = this.getPageCount();
                var currentPage = this.getIdFromPage(currentPage);

                if (totalPages > currentPage) {
                    var nextPageId = this.getNextPageId();
                    var nextPageContents = [...this.getContentsOfPage(nextPageId)];
                    if (nextPageContents[0].childNodes[0].nodeName === "TABLE" && nextPageContents[0].childNodes[0].getAttribute("data-table-id") === currenTableId) {
                        var linkedTable = nextPageContents[0].childNodes[0];

                        var insertBeforeNode = linkedTable.rows[0].childNodes[reducibleCellIndex].childNodes[0];
                        var tableLastRowId = [...table.rows][table.rows.length - 1].getAttribute("rowId");
                        var lastRowOfTable = [...table.rows][table.rows.length - 1];
                        //If there is an id set on last row of table , it mean sit was a linked row, which has been splitted.
                        if (tableLastRowId === null) {
                            var firstRowOfLinkedTable = [...linkedTable.rows][0];
                            firstRowOfLinkedTable.parentNode.insertBefore(lastRowOfTable, firstRowOfLinkedTable);
                            return;
                        }

                        // NOTE: we also want to create a new row itself when a singlw cell was not splitted to create a different row.
                        // var linkedtableCellId = linkedTable.rows[0].childNodes[reducibleCellIndex].getAttribute('cell-id')
                        // alert(linkedtableCellId)
                        if (insertBeforeNode === undefined) {
                            // There is no page below,
                            // NOTE: this order of opertaion is very important,
                            // (
                            //     1. Create a linked table.
                            //     2. remove the table row
                            //)
                            // Create a linked table first , This appends an extra table to the same page,
                            // While creating a linked table, height is checked, if there is overflow, reshifting happens,
                            // Then remove the table row that has to be removed.
                            // Reversing the order of opertaion, i.e, first removing the table row then adding a linked table
                            // to same page won't have any affect as the height will remain same & no reshifting will occur, sigh!

                            var linkedTable = this.createLinkedTable(currenTableId);
                            [...table.rows][table.rows.length - 1].parentNode.removeChild([...table.rows][table.rows.length - 1]);
                            return;
                        }

                        // If there is insertBeforeNode, which means we are probably going to split rows into linked rows.

                        // lastRowOfTable.setAttribute('rowId',this.guidGenerator())
                        var nn = removableNodes.pop();
                        if (insertBeforeNode && insertBeforeNode.nodeName === "#text") {
                            var br = document.createElement("br");

                            linkedTable.rows[0].childNodes[reducibleCellIndex].childNodes[0].parentNode.insertBefore(br, linkedTable.rows[0].childNodes[reducibleCellIndex].childNodes[0]);
                            insertBeforeNode = br;
                        }
                        // linkedTable.rows[0].childNodes[reducibleCellIndex].appendChild(insertBeforeNode)

                        insertBeforeNode.parentNode.insertBefore(nn, insertBeforeNode);

                        //Check after removing node last row became empty , if so rmeove it.

                        var isLastTDEmpty = true;
                        noedsInLastCell = [...table.rows[table.rows.length - 1].childNodes];
                        noedsInLastCell.forEach((td) => {
                            if (td.textContent) {
                                isLastTDEmpty = false;
                            }
                        });

                        if (isLastTDEmpty) {
                            [...table.rows][table.rows.length - 1].parentNode.removeChild([...table.rows][table.rows.length - 1]);
                        }

                        // removableNodes.forEach(n=>{
                        //     insertBeforeNode.parentNode.insertBefore(insertBeforeNode, insertBeforeNode)
                        //     insertBeforeNode = n
                        // })
                    } else {
                        var newTable = this.createLinkedTable(currenTableId);
                        var insertBeforeNode = [...newTable.rows[0].childNodes][0];
                        removableNodes.forEach((n) => {
                            insertBeforeNode.parentNode.insertBefore(n, insertBeforeNode);
                            insertBeforeNode = n;
                        });
                    }
                } else {
                    // There is no page below,
                    // NOTE: this order of opertaion is very important,
                    // (
                    //     1. Create a linked table.
                    //     2. remove the table row
                    //)
                    // Create a linked table first , This appends an extra table to the same page,
                    // While creating a linked table, height is checked, if there is overflow, reshifting happens,
                    // Then remove the table row that has to be removed.
                    // Reversing the order of opertaion, i.e, first removing the table row then adding a linked table
                    // to same page won't have any affect as the height will remain same & no reshifting will occur, sigh!
                    // var tables=[];
                    // nodeGroup.forEach(group=>{
                    //     var newTable = this.createLinkedTable(currenTableId);
                    //     tables.push(newTable)
                    // })

                    var isLastTDEmpty = true;
                    noedsInLastCell.forEach((td) => {
                        if (td.textContent) {
                            isLastTDEmpty = false;
                        }
                    });

                    var lastRowOfTable = [...table.rows][table.rows.length - 1];
                    // If all cells in the last row of table is empty

                    if (isLastTDEmpty) [...table.rows][table.rows.length - 1].parentNode.removeChild([...table.rows][table.rows.length - 1]);
                    else {
                        //If last row needs splitting, set a common ids to last rows of first table and first row of second table.
                        if (removableNodes.length >= 1) {
                            lastRowOfTable.setAttribute("rowId", this.guidGenerator());
                            [...newTable.rows][0].setAttribute("rowId", this.guidGenerator());
                        }
                        // If there was only one text line in last table row, remove it
                        // if(removableNodes.length === 1){
                        //     ;[...table.rows][table.rows.length -1].parentNode.removeChild([...table.rows][table.rows.length -1])
                        // }

                        var insertBeforeNode = removableNodes.pop();
                        newTable.rows[0].childNodes[reducibleCellIndex].appendChild(insertBeforeNode);

                        removableNodes.forEach((n) => {
                            insertBeforeNode.parentNode.insertBefore(n, insertBeforeNode);
                            insertBeforeNode = n;
                        });

                        //Check after removing node last row became empty , if so rmeove it.

                        var isLastTDEmpty = true;
                        noedsInLastCell = [...table.rows[table.rows.length - 1].childNodes];
                        noedsInLastCell.forEach((td) => {
                            if (td.textContent) {
                                isLastTDEmpty = false;
                            }
                        });

                        if (isLastTDEmpty) {
                            [...table.rows][table.rows.length - 1].parentNode.removeChild([...table.rows][table.rows.length - 1]);
                        }
                    }
                }
            }
        }
    }

    // emitPageHeightLimitCrossedEvent(){

    //     var evt = new CustomEvent('')
    // }

    handleEnter(e) {
        // check in which page enter is pressed
        // var pageId = window.getSelection().anchorNode.parentNode.attributes.class.nodeValue
        // var activeElement = window.getSelection().anchorNode

        var activeElement = this.selectedElement ? this.selectedElement.parentNode : this.getActiveNode();

        var pageId = activeElement.closest(".neo-page").id;

        var currentNode = this.getActiveNode();

        if (currentNode.classList.contains("table--container")) {
            var newRow = this.createNewRow();
            newRow.classList.add(pageId);
            currentNode.parentNode.insertBefore(newRow, currentNode.nextSibling);

            if (window.getSelection().anchorOffset === 0) {
                newRow.appendChild(currentNode.querySelector("table"));
                currentNode.classList.remove("table--container");
                let brNode = document.createElement("br");
                currentNode.appendChild(brNode);
                newRow.classList.add("table--container");
                this.focusCursor(newRow);
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
            }
            if (window.getSelection().anchorOffset === 1) {
                newRow.classList.remove("table--container");
                this.focusCursor(newRow);
                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
            }
        }

        let headerFooterHeight = this.getHeaderAndFooterHeight(pageId);

        //check total height of page, can we expand more content here??
        // let pageHeight = [...document.querySelectorAll(`..${pageId} `)].reduce((a,b) => a+b.offsetHeight, headerFooterHeight.header+headerFooterHeight.footer)
        let pageHeight = this.getPageHeight(pageId);

        if (this.selectedElement) {
            this.createNewLineElement(pageId);
            this.selectedElement = null;
        }

        if (pageHeight >= this.pageBreakHeight) {
            //             this.handleTableSplt()

            // pageHeight = [...document.querySelectorAll(`.${pageId}`)].reduce((a,b) => a+b.offsetHeight, headerFooterHeight.header+headerFooterHeight.footer);
            pageHeight = this.getPageHeight(pageId);

            if (pageHeight <= this.pageBreakHeight) {
                return;
            }

            this.handleTableSplt(pageId);

            //             pageHeight = [...document.querySelectorAll(`.${pageId}`)].reduce((a,b) => a+b.offsetHeight, headerFooterHeight.header+headerFooterHeight.footer);
            //             if (pageHeight <= this.pageBreakHeight){
            //                 return;
            //             }

            //             this.handleBulletListOnEnter()
            //
            //
            //             pageHeight = [...document.querySelectorAll(`.${pageId}`)].reduce((a,b) => a+b.offsetHeight, headerFooterHeight.header+headerFooterHeight.footer);
            //             if (pageHeight <= this.pageBreakHeight){
            //                 return;
            //             }

            //check if enter was pressed on the last row of a page
            var actvieNode = this.getActiveNode();
            var pageContents = [...this.getContentsOfPage(pageId)];

            this.shiftContentToNextPages(pageId);

            if (pageContents[pageContents.length - 1] === actvieNode) {
                /* enter was pressed on the last row of a page,
                / Create a new empty row & add it to the next page,
                / foucs cursor to the next page
                */
                // var row = this.createNewRow()
                // row.classList.add(pageId)

                var nextPageId = this.getNextPageId();
                var nextPage = this.getPageById(nextPageId);
                // this.prependInPage(nextPage, [row])

                // focus cursor to the first editable row of next page
                this.focusCursor(nextPage.querySelector(".content--area").firstElementChild);
            }
        }
    }

    handleInput(container) {
        var that = this;
        container = container || this.container;

        container.addEventListener("keydown", function (e) {
            switch (e.keyCode) {
                case 8: {
                    that.handleBackspace(e);
                    // that.activeEditorNode = that.getActiveNode()

                    break;
                }
                case 13: {
                    if (that.isShift) {
                        e.preventDefault();
                        var pageID = that.getActivePageId();
                        that.createNewLineElement(pageID);
                        return;
                    }
                    that.handleEnter(e);

                    break;
                }
                case 16:
                    that.isShift = true;
                    break;

                default:
            }
        });

        container.addEventListener("keyup", function (e) {
            switch (e.keyCode) {
                case 13: {
                    that.handleEnter(e);
                    var node = that.getActiveNode();
                    if (node.closest(".neo-page")) {
                        that.activeEditorNode = node;
                    }
                    break;
                }
                case 8: {
                    that.handleBackspaceAfterKeyUp();
                    break;
                }
                default:
            }
        });
    }

    addTableTippy(table) {
        // Add tippy to table
        var tableOptions = tippy(table, {
            // 'trigger':'click',
            content: popOverOptions,
            placement: "bottom",
            allowHTML: true,
            interactive: true,
            theme: "light",
        });
    }

    createTable(rowNum, colNum) {
        let tag = document.createElement("table");
        tag.className = "table table-bordered table-center neo--table";
        tag.style.marginBottom = "0px";
        tag.style.width = "100%";
        tag.setAttribute("draggable", "false");
        let row = "";
        let width = (100 / colNum).toFixed(2) + "%";
        if (this.isHeader == "0") {
            row = row + "<thead><tr>";
            for (let j = 0; j < colNum; j++) {
                let col = "<th style='padding:4px;width: " + width + "'></th>";
                row = row + col;
            }
            row = row + "</tr></thead>";
        }
        row = row + "<tbody>";
        for (let i = 0; i < rowNum; i++) {
            row = row + "<tr>";
            for (let j = 0; j < colNum; j++) {
                let col = "<td style='width: " + width + "; background-color: initial !important;padding:4px'></td>";
                row = row + col;
            }
            row = row + "</tr>";
        }
        row = row + "</tbody>";
        tag.innerHTML = row;
        return tag;
    }
    guidGenerator() {
        var S4 = function () {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        };
        return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
    }

    addTable(rowNum, colNum) {
        var table = this.createTable(rowNum, colNum);
        // assign custom id to the table.
        var randomId = this.guidGenerator();
        table.setAttribute("data-table-id", randomId);
        // table.style.setProperty('table-layout','fixed')
        table.style.setProperty("word-break", "break-all");
        let range = this.getRange();
        range.insertNode(table);
        table.parentNode.classList.add("table--container");
        var nextTableSibling = table.nextSibling;
        if (nextTableSibling && nextTableSibling.nodeName === "BR") nextTableSibling.parentNode.removeChild(nextTableSibling);
        let pageId = this.getActivePageId();
        if (this.checkNeedsPageBreak(pageId)) {
            this.shiftContentToNextPages(pageId);
        }
    }

    getPrevCursor() {
        return this.prevCursorSelection;
    }

    getSelectedElem() {
        return this.selectedElement;
    }

    changeFontFamily(fontFamily) {
        let range = window.getSelection().getRangeAt(0);
        if (range.collapsed) return;
        if (this.getActiveNode().closest("TD")) {
            this.getActiveNode().closest("TD").style.setProperty("font-family", fontFamily);
            return;
            // // Clear selected Range
            // range.insertNode(ul)
            // range.setStart(node, startOffset);
            // range.setEnd(endNode, endOffset);
            // window.getSelection().addRange(range)
        }
        let nodes = this.getNodes(null, true);
        nodes.forEach((node) => (node.style.fontFamily = fontFamily));
    }

    addPlaceholder() {
        let range = window.getSelection().getRangeAt(0);
        if (range.collapsed) return;
        if (this.getActiveNode().closest("UL")) {
            let node = this.getActiveNode().closest("UL");
            node.classList.add("placeholder-custom");
            node.style.padding = "0px !important";
            return;
        }
        if (this.getActiveNode().closest("TD")) {
            let nodes = this.getNodes();
            let html = document.createElement("span");
            nodes.forEach((node) => {
                if (node.nodeName !== "SPAN" && node.nodeName !== "#text") {
                    node.classList.add("placeholder-custom");
                    return;
                }
                let span = document.createElement("span");
                span.classList.add("placeholder-custom");
                span.style.display = "block";
                span.appendChild(node);
                html.appendChild(span);
            });
            range.insertNode(html);
            return;
        }
        if (range.commonAncestorContainer.className == "content--area") {
            let nodes = this.getNodes();
            nodes.forEach((node) => {
                if (node.nodeName !== "DIV") {
                    node.classList.add("placeholder-custom");
                    return;
                }
                let div = document.createElement("div");
                div.classList.add("placeholder-custom");
                div.appendChild(node);
                div.innerHTML = node.innerHTML;
                range.insertNode(div);
            });
            return;
        }
        let nodes = this.getNodes(null, true);
        nodes.forEach((node) => {
            node.classList.add("placeholder-custom");
        });
    }

    rotateText() {
        let range = window.getSelection().getRangeAt(0);
        if (range.collapsed) return;
        if (this.getActiveNode().closest("TD")) {
            const nodes = this.getActiveNode().closest("TD");
            nodes.innerHTML = `<span style="transform:rotate(180deg);writing-mode:vertical-rl;">${nodes.innerHTML}</span>`;
            return;
        }
        let nodes = this.getNodes(null, true);
        nodes.forEach((node) => {
            if (window.getSelection().containsNode(node, true)) {
                node.style.transform = `rotate(180deg)`;
                node.style.writingMode = "vertical-rl";
            }
        });
    }

    changeIcon(pageSelectedIcon, selectedIcon, iconType, iconBackgroundColor = "cyan") {
        let iconObj = document.createElement("img");
        iconObj.width = 30;
        iconObj.height = 30;
        iconObj.src = selectedIcon;
        iconObj.className = "neo--report--icons";
        SVGInject(iconObj);
        pageSelectedIcon.parentNode.replaceChild(iconObj, pageSelectedIcon);
        setTimeout(() => {
            [...document.querySelectorAll(".neo--report--icons")].forEach((ico) => {
                // this.setupIconClickListener(ico)
                // var wrapped = this._wrapNode(ico, document.createElement("div"))

                if (!ico.style.getPropertyValue("border-radius")) {
                    if (iconType) {
                        if (iconType === "squareIcon") {
                            ico.style.background = iconBackgroundColor;
                            ico.style.padding = "4px";
                            ico.style.borderRadius = "4px";
                        }
                        if (iconType === "roundIcon") {
                            ico.style.background = iconBackgroundColor;
                            ico.style.padding = "4px";
                            ico.style.borderRadius = "50%";
                        }
                    }
                }

                this.setupIconClickListener(ico);
                // if(!ico.nextSibling){
                this.insertAfterNodeInPage(document.createTextNode("\u00A0"), ico);

                // }
            });
        }, 2000);
    }

    addHyperlinkTag(hyperlinkDisplay, hyperlinkText, hyperlinkPage) {
        let range = this.activeEditorSelection;
        let link = hyperlinkText ? hyperlinkText : `#page--${hyperlinkPage}`;
        let anchortag = document.createElement("a");
        anchortag.setAttribute("href", link);
        anchortag.innerText = hyperlinkDisplay;
        anchortag.setAttribute("target", "_blank");
        anchortag.setAttribute("title", link);
        range.deleteContents();
        range.insertNode(anchortag);
    }

    saveSelection() {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                return sel.getRangeAt(0);
            }
        } else if (document.selection && document.selection.createRange) {
            return document.selection.createRange();
        }
        return null;
    }

    fixConstraints() {
        const tables = [...document.querySelectorAll("table")];
        tables.forEach((table) => {
            table.style.setProperty("word-break", "break-all");
        });
    }
}
